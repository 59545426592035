import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader.js";


function PrivacyPolicy() {
  return (
    <>
      <main style={{ height: "50vh" }}>
      
        <p>
          You're now inside <em>Privacy Policy</em>
        </p>
      </main>
     
    </>
  );
}

export default PrivacyPolicy
