import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from 'react-dom/client';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Account from "./Presentation/Pages/Account";
import Login from "./Presentation/Pages/Login";
import Register from "./Presentation/Pages/Register";
import MyCamicode from "./Presentation/Pages/MyCamicode";
import YourCamicodes from "./Presentation/Pages/YourCamicodes";
import ReceivedCamiCode from "./Presentation/Pages/ReceivedCamiCode";
import AboutCami from "./Presentation/Pages/AboutCami";
import PrivacyPolicy from "./Presentation/Pages/PrivacyPolicy";
import { RequireAuth } from './Business/AuthenticationBusiness';
import ForgetPassword from './Presentation/Pages/ForgotPassword';
import MyPaymentMethods from "./Presentation/Pages/MyPaymentMethods";
import MyProfile from "./Presentation/Pages/MyProfile";
import MyTransaction from "./Presentation/Pages/MyTransaction";
import RecordVideo from "./Presentation/Pages/RecordVideo";
import Open from "./Presentation/Layouts/Open";
import AppLayout from "./Presentation/Layouts/App";
import MediaLayout from "./Presentation/Layouts/MediaLayout";
import ScanCamicode from './Presentation/Pages/ScanCamiCode';
import Watch from "./Presentation/Pages/Watch";
import CamiCard from "./Presentation/Pages/CamiCard";
import SentCamicode from "./Presentation/Pages/SentCamiCode";
import CamiSteps from "./Presentation/Pages/CamiSteps";
import CamiCardReceived from "./Presentation/Pages/CamiCardReceived";
import OrderComplete from "./Presentation/Pages/OrderComplete";
import FreeCami from "./Presentation/Pages/FreeCami";
import ResetPassword from "./Presentation/Pages/ResetPassword";
import UploadVideo from "./Presentation/Components/UploadVideo";
import CamiForever from "./Presentation/Pages/CamiForever";
import ForeverCami from "./Presentation/Pages/Forevercami";
import ForeverCamiCodes from "./Presentation/Pages/Forevercami";



const root = ReactDOM.createRoot(document.getElementById("root"));
const reload = () => window.location.reload();

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<><CamiSteps /></>} />
        <Route path="account" element={<RequireAuth><Account /></RequireAuth>} />
        <Route path="my-camicodes" element={<RequireAuth><MyCamicode /></RequireAuth>} />
        <Route path="your-camicodes" element={<RequireAuth><YourCamicodes /></RequireAuth>} />
        <Route path="created-camicode" element={<RequireAuth><SentCamicode /></RequireAuth>} />
        <Route path="received-camicode" element={<RequireAuth><ReceivedCamiCode /></RequireAuth>} />
        <Route path="my-payment-methods" element={<RequireAuth><MyPaymentMethods /></RequireAuth>} />
        <Route path="my-profile" element={<RequireAuth><MyProfile /></RequireAuth>} />
        <Route path="my-transaction" element={<RequireAuth><MyTransaction /></RequireAuth>} />
        <Route path="camicard" element={<><CamiCard /></>}></Route>
        <Route path="camicard-received" element={<><CamiCardReceived /></>}></Route>
        <Route path="free-cami" element={<RequireAuth><FreeCami /></RequireAuth>} />
        <Route path="forever-cami" element={<RequireAuth>< ForeverCamiCodes/></RequireAuth>} />
      </Route>
      <Route element={<Open />} >
        <Route path="login" element={<><Login /></>} />
        <Route path="register" element={<><Register /></>} />
        <Route path="forgot-password" element={<ForgetPassword />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cami-steps" element={<CamiSteps />} />      
      </Route>
      <Route element={<MediaLayout />} >
        <Route path="create-cami" element={<RecordVideo />} />
        <Route path="scan-cami" element={<ScanCamicode />} />
        <Route path="upload-video" element={<UploadVideo />} />
        <Route path="watch" element={<Watch />} />
        <Route path="order-complete" element={<OrderComplete />} />
      </Route>
      <Route path="reset-password" element={<ResetPassword/>}/>
      <Route path ="cami-forever" element={<CamiForever/>}/>
      {/* <Route path="/robots.ext" onEnter={reload} />
      <Route path="/apple-app-site-association" element={<apple-app-site-association/>} onEnter={reload} /> */}
    </Routes>
  </BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
