import React from "react";
import { useState, useEffect } from "react";

import { getCurrentSignedInUser } from "../../Repository/Authentication/Firebase";
import logo from "../../assets/images/logo-purple-horizontal.png";
import logoWhite from "../../assets/images/logo-white-horizontal.png";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Drawer from "./Drawer";
import MenuLink from "./MenuLink";

import { colors, useMediaQuery, useTheme } from "@mui/material";
import ManageAuthModule from "./ManageAuthModule";

const AppHeader = (props) => {
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down('md'));
  const [dark, setDark] = useState(true);
  const [background, setBackground] = useState({ backgroundColor: "#981D97" });
  const [login, setLogin] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [state, setState] = useState(false);
  const [rerender, setRerender] = useState(3);

  const toggleDrawer = (openDrawer) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(true);
  };

  const handleOpenNavMenu = (event) => {
    setState(false);
    setRerender(Math.round(Math.random() * 100));
    setAnchorElNav(event.currentTarget);
    if (!(event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift"))) {
      setState(true);
      setRerender(Math.round(Math.random() * 100));
    }
  };

  useEffect(() => {
    if (dark) {
      setBackground({ backgroundColor: "#981D97" });
    } else {
      setBackground({ backgroundColor: "#fff" });
    }
  }, [dark]);

  useEffect(() => {
    getCurrentSignedInUser().subscribe((event) => {
      if (event.state === "logged in") {
        setLogin(true);
      } else if (event.state === "logged out") {
        setLogin(false);
      }
    });
  }, []);


  const renderLogo = () => {
    if (typeof props.type !== 'undefined' && props.type === 'floating') {
      if (smallDevices) {
        return (
          <Box sx={{ flexGrow: 1, display: { xs: 0, md: "flex" }, }} >
            <a href="https://camicode.com" underline="none" >
              <img src={logo} alt="Logo" style={{ height: 35 }} />
            </a>
          </Box>
        );
      } else {
        return (
          <Box sx={{ flexGrow: 1, display: { xs: 0, md: "flex" }, }} >
            <a href="https://camicode.com" underline="none" >
              <img src={logoWhite} alt="Logo" style={{ height: 35 }} />
            </a>
          </Box>
        );
      }
    } else {
      if (dark) {
        return (
          <Box sx={{ flexGrow: 1, display: { xs: 0, md: "flex" } }}>
            <a href="https://camicode.com" underline="none" >
              <img src={logoWhite} alt="Logo" style={{ height: 35 }} />
            </a>
          </Box>
        );
      } else {
        return (
          <Box sx={{ flexGrow: 1, display: { xs: 0, md: "flex" }, }} >
            <a href="https://camicode.com" underline="none" >
              <img src={logo} alt="Logo" style={{ height: 35 }} />
            </a>
          </Box>
        );
      }
    }
  }

  const renderDrawer = () => {
    let buttonColorStyle = { color: "white", zIndex: '1199', justifyContent: "flex-end", flexGrow: 1, display: { xs: "flex", md: "none" } };
    if (typeof props.type !== 'undefined' && props.type === 'floating') {
      if (smallDevices) {
        buttonColorStyle['color'] = background.backgroundColor;
      } else {
        buttonColorStyle['color'] = 'white';
      }
    } else if (dark) {
      buttonColorStyle['color'] = 'white';
    } else {
      buttonColorStyle['color'] = background.backgroundColor;
    }
    return (
      <div >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          sx={buttonColorStyle}
        >
          <MenuIcon />
        </IconButton>
      </div>
    );
  }

  const renderAppBar = () => {
    const appBarContents = <Container maxWidth="xl">
      <Toolbar disableGutters>
        {renderLogo()}
        <MenuLink key={rerender} dark={dark} login={login} state={state} ></MenuLink>
        {renderDrawer()}
      </Toolbar>
    </Container>;
    if (typeof props.type !== 'undefined' && props.type === 'floating') {
      let appbarStyle = { backgroundColor: 'transparent', position: 'absolute' };
      if (smallDevices) appbarStyle = { backgroundColor: 'transparent', position: 'absolute' };
      else appbarStyle = { backgroundColor: background.backgroundColor, position: 'relative' }
      return <AppBar position="static" sx={appbarStyle} elevation={0} >
        {appBarContents}
      </AppBar>
    } else {
      return <AppBar position="static" sx={{ backgroundColor: background.backgroundColor }} >
        {appBarContents}
      </AppBar>
    }
  };

  return (
    <>
      {renderAppBar()}
      <Drawer login={login} dark={dark} state={state} key={rerender} ></Drawer>
    </>
  );
};

export default AppHeader;
