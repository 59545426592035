import { InputBase } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { validateEmail, validateName } from '../../Business/AuthenticationBusiness';


function ShareReceivedCamiCode(props) {

    const [firstName, setFirstName] = useState("");
    const [formName, setFormName] = useState('');
    const [formToName, setFormToName] = useState('');
    const [formToEmail, setFormToEmail] = useState('');
    const [formSubject, setFormSubject] = useState('');

    const [videoEmailError, setVideoEmailError] = useState('');
    // const [videoNameError, setVideoNameError] = useState('');
    const inputFieldColor = "#ecedee";
    const mainColor = "#981D97";

    const errorText = {
        color: "red",
        fontSize: 13,
        marginTop: 1,
        marginBottom: 1,
    };

    const propFromName = props.fromCurrentUserName;
    const propSubject = props.currentSubject;

    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000"),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));
    useEffect(() => {
        setFormName(propFromName);
        setFormSubject(propSubject);
    }, [])

    useEffect(() => {
        localStorage.setItem('CURRENTNAME', formName);
        localStorage.setItem('TOEMAIL', formToEmail);
        localStorage.setItem('TONAME', formToName);
        localStorage.setItem('TOSUBJECT', formSubject);
    }, [formName, formToEmail, formToName, formSubject])

    function validate() {
        const _email = validateEmail(formToEmail);
        const _name = validateName(formName);
        if (!_email) {
            setVideoEmailError("Please enter a valid email");
        } else {
            setVideoEmailError("");
        }
        // if (!_name) {
        //     setVideoNameError("Please enter a valid name");
        // } else {
        //     setVideoNameError("");
        // }
        if (!_name || !_email) {
            // alert('coming inside');
            return;
        }
        else return props.handleCloseModal();
    }

    return (
        <>
            <form style={{ width: "275px" }}>
                <InputBase
                    autoFocus
                    margin="dense"
                    id="Name"
                    placeholder="From"
                    fullWidth
                    variant="standard"
                    value={(formName) ? (formName) : ("")}
                    onChange={(event) => { setFormName(event.target.value) }}
                    required
                    sx={{
                        backgroundColor: "#ecedee", marginTop: 2, padding: 1
                    }}
                />
                <InputBase
                    margin="dense"
                    id="to"
                    placeholder="To(name)"
                    fullWidth
                    variant="standard"
                    value={formToName}
                    onChange={(event) => { setFormToName(event.target.value) }}
                    required
                    sx={{
                        backgroundColor: "#ecedee", marginTop: 2, padding: 1
                    }}
                />
                {/* {videoNameError && <p style={errorText}> {videoNameError} </p>} */}
                <InputBase
                    margin="dense"
                    id="email"
                    placeholder="eMail"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={formToEmail}
                    onChange={(event) => { setFormToEmail(event.target.value) }}
                    required
                    sx={{
                        backgroundColor: "#ecedee", marginTop: 2, padding: 1
                    }}
                />
                {videoEmailError && <p style={errorText}> {videoEmailError} </p>}
                <InputBase
                    margin="dense"
                    id="subject"
                    placeholder="Subject"
                    fullWidth
                    variant="standard"
                    value={formSubject || props.shareSubject}
                    onChange={(event) => { setFormSubject(event.target.value) }}
                    disabled
                    sx={{
                        backgroundColor: "#ecedee", marginTop: 2, padding: 1
                    }}
                />
                <Box sx={{ textAlign: "center", marginTop: 3 }}>
                    <CustomButton sx={{ marginRight: 1 }} onClick={() =>validate()}>Send</CustomButton>
                    <CustomButton sx={{ marginLeft: 1 }} onClick={() => props.handleCancelModal()}>Cancel</CustomButton>
                </Box>
            </form>
        </>
    )
}

export default ShareReceivedCamiCode