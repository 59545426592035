import { Box, Button, Card, CircularProgress, Dialog, DialogTitle, Fab, InputBase, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useRef, useState } from "react";
import { getCamitagFromUrl, getRecordingTimeFromLS, getRecordingTimeFromUrl, getSupportedMedia, getVideoConstraints, identifyFacingModeSupport, mapCamitagAndVideo, requestMedia, uploadRecordedVideoBusiness } from "../../Business/MediaBusiness";
import IconButton from '@mui/material/IconButton';
import { CloudUpload, LocalActivity, RadioButtonChecked, StopCircle } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { createUser, getCamiCode, getCamitagFromId, getCardDetails, getUserByAuthId, UpdateCAMIApi, uploadPdfImage } from '../../Repository/Api';
import { styled } from "@mui/material/styles";
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../Components/Payment.css';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { baseURL } from '../../Repository/Api';
import adapter from 'webrtc-adapter';
import { getBearerToken, getVideoStatus, generateUploadToken } from "../../Repository/ApiVideo";
import { count } from 'rxjs';
import MuiAlert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Loader from './Loader';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function UploadVideo() {
    const VideoPreviewRef = useRef();
    const VideoReplayRef = useRef();
    const inputRef = useRef();
    const UploadPdfRef = useRef();
    const theme = useTheme();
    const navigate = useNavigate();
    const smallDevices = useMediaQuery(theme.breakpoints.down('md'));
    const [background, setBackground] = useState({ backgroundColor: "#981D97" });
    let [isUploading, SetIsUploading] = useState(false);
    let [fileObj, setFileObj] = useState(null);
    let [percentageUploaded, setPercentageUploaded] = useState(0);
    let [videoLink, setVideoLink] = useState(null);
    let [previewUrl, setPreviewUrl] = useState(null);
    let [liveStreamId, setLiveStreamId] = useState(null);
    let [appState, setAppState] = useState("IDLE");
    let [openDialog, SetOpenDialog] = useState(false);
    let [openCamiCodeDialog, setOpenCamiCodeDialog] = useState(false);
    let [recordingTimer, setRecordingTimer] = useState(0);
    let [camiTitle, setCamiTitle] = useState('');
    let [camisID, setCamisID] = useState('');
    const [pass, setPass] = useState('');
    const [paid, setPaid] = useState(false);
    const [facingModeSupported, setFacingModeSupported] = useState(true);
    const [currentEmail, setCurrentEmail] = useState("");
    const [firstName, setFirstName] = useState("")
    const [id, setId] = useState("")
    const [theItemID, setTheItemID] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [videoDuration, setVideoDuration] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [uploadURL, setUploadURL] = useState('');
    const [camiId, setCamiId] = useState(null);
    let [isPaused, setIsPaused] = useState(true);
    let [replayStart, setReplayStart] = useState(false);
    let [clientSecret, setClientSecret] = useState("");
    const [videoId, setVideoId] = useState(null);
    const [videoSize, setVideoSize] = useState("");
    const [source, setSource] = useState();
    let [isRecording, SetIsRecording] = useState(0);
    const [openAlert, setOpenAlert] = useState(false)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [dialogLoading , setDialogLoading]=useState(false);
    const [camiTypeId, setCamiTypeId] = useState("");
    const [showAlert , setShowAlert] = useState(false);
    const [payOption,setPayOption] = useState("");
    
    // let stripePromise = loadStripe("pk_test_51KzwegAfbpe3qNzlEAM3RafjyKR1yg6KfcmPvUsbsoL85l2HhNMbjakJBJuPmuNMbRiO6NDWC0IFDgqnglUcZuvI00JfTJUM2I");
    let stripePromise = loadStripe("pk_live_51KzwegAfbpe3qNzleePHdOPEzh972xmDGFvyPzJ2joGCueN2BE7DsuFbBiQ4tvFMpkRxvZ9IwPQVXV9AhSmrPBkt00NwVeksJc");
    const stripe_appearance = { theme: 'stripe' };
    const stripe_options = {
        clientSecret: clientSecret,
        theme: 'stripe'
    };
    const [cardData, setCardData] = useState("");
    const [isFree, setIsFree] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    // function onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages);
    //     setPageNumber(1);
    //     setLoading(false);
    // }
    function changePage(offSet) {
        setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }
    function changePageBack() {
        changePage(-1)
    }
    function changePageNext() {
        changePage(+1)
    }
    const mainColor = "#981D97";
    const whiteColor = "#fff";
    const paperBgColor = "#f7f8f9"
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000"),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));
    const circularProgress = {
        color: background.backgroundColor,
        position: 'absolute',
        zIndex: 1,
        left: '-31px',
        top: '-2px',
        transform: "rotateY(180deg) rotateZ(-90deg)  !important",
        // transform: "rotateY(180deg)
        // transform: "rotateZ(-90deg) 
    };
    const paddingPurchaseLeft =
    {
        display: 'block',
        flexDirection: "column",
        alignItems: 'center',
        paddingLeft: smallDevices ? (1) : (2),
        paddingRight: smallDevices ? (1) : (2),
    }
    const hide = {
        display: "none"
    }
    const dialogButtons = {
        display: 'block',
        margin: '10px auto',
        width: '100%',
        borderRadius: 20,
        width: "60%"
    }
    const dialogButton = {
        display: 'block',
        margin: '10px auto',
        width: '100%',
        borderRadius: 20,
        fontSize: "12px",
        width: "100%"
    }
    const ImagePreviewStyleSM = {
        width: '100vw',
        objectFit: 'contain',
        backgroundColor: "transparent",
        height: "60vh"
    };
    const ImagePreviewStyleMD = {
        objectFit: 'cover',
        height: 'calc(100vh - 146px)',
        backgroundColor: "transparent"
    };
    const VideoPreviewStyleSM = {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100vw',
        height: window.innerHeight,
        objectFit: 'cover',
    };
    const VideoPreviewStyleMD = {
        width: '50vw',
        height: 'calc(100vh - 68px)',
        objectFit: 'cover'
    };
    const recordBtnContainerReplay = {
        position: 'absolute',
        bottom: '1px',
        zIndex: '1051',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: smallDevices ? ('100vw') : ('50vw'),
        height: '100px'
    };
    const recordBtnInnerContainer = {
        m: 1,
        position: 'relative',
        margin: '0 auto',
        height: '100%'
    };
    const fabButton = {
        position: 'absolute',
        left: '-28px',
        top: '0px',
        border: "4px solid",
        borderColor: (isRecording === 1) ? ("transparent") : ("white"),
        borderRadius: "50%",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    }
    const fabButton2 = {
        display: 'flex',
        textAlign: 'center',
        position: 'absolute',
        bottom: 44,
        right: 25,
        zIndex: "1050",
        border: "4px solid",
        borderColor: "transparent",
        borderRadius: "50%",
        // backgroundColor: "transparent",
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: mainColor,
        },
    }
    const videoUpload = {
        display: "flex",
        justifyContent: "space-around"
    }
    const xlPage = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "400px",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 15,
        backgroundColor: whiteColor
    };
    const smPage = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "100px"
    };
    const errorText = {
        color: "red",
        fontSize: 13,
        marginTop: 1,
        marginBottom: 1,
    };
    const pageStyle = {
        // minHeight: " calc(100vh - 50px)",
        backgroundColor: "#ce34",
        height: "90%",
        padding: 5,
        backgroundColor: paperBgColor,
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
        setLoading(false);
    }
    useEffect(constructor, []);
    const handleFileChange = (event) => {
        let file = event.target.files && event.target.files[0];
        setVideoSize(file.size)
        const video = document.createElement('video');
        video.src = window.URL.createObjectURL(file);
        let videoURL = video.src;
        setUploadURL(videoURL);
        SetOpenDialog(true);
        video.preload = 'metadata';
        uploadComplete({
            "previewUrl": videoURL,
            "liveStreamId": '',
            "state": "PREVIEW",
            "fileObj": file
        })
        video.onloadedmetadata = async () => {
            const duration = Math.trunc(video.duration);
            setVideoDuration(duration)
            if (duration <= recordingTimer / 1000) {
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event) => {
                    }
                }
            } else {
                // setOpenModal(true);
                SetOpenDialog(false);
                setOpenAlert(true);
                if (recordingTimer == 180) {
                    recordingTimer = 180
                } else {
                    recordingTimer = 29
                }
            }
        };
    }


    useEffect(() => {
        // const isSupported = identifyFacingModeSupport()
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return setFacingModeSupported(true)
        }
        else return setFacingModeSupported(false)
        // if (isSupported == true) {
        //     setFacingModeSupported(true)
        // } else if (isSupported == false) {
        //     setFacingModeSupported(false)
        // }
    }, [])

    useEffect(() => {
        previewPlayerEventListener();
    }, []);

    function previewPlayerEventListener() {
        VideoReplayRef.current.addEventListener('ended', (event) => {
            setIsPaused(!isPaused);
            SetOpenDialog(true);
        });
    }

    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    getUserEmailFromUid(user.uid);
                    setCurrentEmail(user.email);
                }).catch((err) => {
                    console.log('error getting user info')
                });
            } else if (user = undefined || null) {
                console.log('error getting user info')
            }
        }
        );
    }, [])
    //get user info - user ID, current email Id, Name
    const getUserEmailFromUid = async (authId) => {
        const response = await getUserByAuthId(authId)
            .then(response => {
                setId(response.id)
                setCurrentEmail(response.email);
                setFirstName(response.firstName)
            }).catch(error => console.log(error, "error receiving data by email"))
    }
    useEffect(() => {
        //get caerd details of current user using user ID, current Email and firstname
        if (id && currentEmail && firstName) {
            getCardDetailsFn();
        }
    }, [id, currentEmail, firstName])

    const getCardDetailsFn = (e) => {
        if (id && firstName && currentEmail) {
            const _getCardDetail = {
                "id": id,
                "name": firstName,
                "email": currentEmail
            };
            getCardDetails(_getCardDetail)
                .then((response) => {
                    setCardData(response)
                }).catch((error) => {
                    setCardData("false")
                })
        } else {
            setCardData("false")
        }
    }

    function getCAMIcodeFromQuery() {
        console.log("from query")
        setDialogLoading(true)
        const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
        if (urlSearchParams.has('p')) {
            const _pass = urlSearchParams.get('p').replace(/ /g, '');
            const camiCodeId = getCamitagFromUrl();
            if (camiCodeId && _pass) {
                setCamiId(camiCodeId);
                getCamiCode(camiCodeId, _pass).then((response) => {
                    if (response["duration"]) {
                        var _duration = (response["duration"] * 1000);
                        setRecordingTimer(_duration);
                        setDialogLoading(false)
                    } else if (response['id']) {
                        setCamisID(response['id'])
                        setDialogLoading(false)
                    }
                    else {
                        setRecordingTimer(29000);
                        setDialogLoading(false)
                    }
                }).catch((error) => {
                    setRecordingTimer(29000);
                    setDialogLoading(false)
                })
            }
        }else{
            setDialogLoading(false)
        }
    }

    function circularIndeterminate() {
        return (
            <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} >
                <CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} />
            </Box>
        );
    }
    function getPasswordFromQuery() {
        const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
        if (urlSearchParams.has('p')) {
            const _pass = urlSearchParams.get('p').replace(/ /g, '');
            // const decodedPathName = decodeURI(redirPath);
            setPass(_pass);
        }
    }   

    useEffect(() => {
        getPaymentInformation();
    }, [pass])
    useEffect(() => {
        const paid = (localStorage.getItem('P')) ? (localStorage.getItem('P')) : ('');
        // if (camitagid){}
        // setCamiId(paid)
        // const uniqueId = localStorage.getItem('UNIQUEID')
        localStorage.removeItem('P');
    }, [])

    function getPaymentInformation() {
        if (pass && camiId) {
            let api = baseURL + '/api/CamiCode/VerifyCamiCode?';
            api += 'camiCode=' + camiId + '&uniqueId=' + pass;
            const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
            fetch(api, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((response) => response.json()).then((result) => {
                if (result['isPaid'] == true) {
                    setPaid(true);
                } else {
                    setPaid(false);
                }
                setIsFree(result['isFree']);

                setCamiTypeId(result["camiTypeId"])
            })
        }
    }

    let getCamitagFromUrl = () => {
        return getCamitag(window.location);
    }

    let getCamitag = (url) => {
        try {
            const urlparams = new URLSearchParams(url.search);
            const _camitagid = urlparams.get('c') || "";
            return _camitagid;
        } catch (error) {
            return "";
        }
    }

    function constructor() {
        // localStorage.setItem('RT','P1');
        console.log(localStorage.getItem("RT"));
        if (localStorage.getItem('RT') == 'P1') {
            handleClickOpenPdf();
            uploadDialog();
            setPayOption(localStorage.getItem("RT"));
            localStorage.removeItem('RT');
        }else{
            handleClickOpenPdf();
            setPayOption("P2")
            uploadDialog();
        }
        getCAMIcodeFromQuery();
        getPasswordFromQuery();
        getRecordingTimeFromQuery();
    }

    useEffect(() => {
        if (!firstName && !currentEmail) {
            getCardDetailsFn();
        }
    }, [recordingTimer]);


    useEffect(() => {
        if (recordingTimer && !firstName && !currentEmail) {
            if (cardData == 'false') {
                //looged in flow
                createPaymentIntent();
            }
        }
    }, [cardData])


    function createPaymentIntent() {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        const itemId = (recordingTimer == 29000) ? (1) : (2);
        setTheItemID(itemId);
        if (itemId && firstName && currentEmail) {
            fetch(baseURL + "/api/stripe/create-payment-intent-new", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    items: {
                        id: itemId,
                        name: firstName,
                        email: currentEmail
                    }
                })
            }).then((res) => res.json())
                .then((data) => {
                    setClientSecret(data.clientSecret)
                });
        } else if (itemId && !firstName && !currentEmail) {
            fetch(baseURL + "/api/stripe/create-payment-intent", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    items: {
                        id: itemId,
                        name: "",
                        email: ""
                    }
                })
            }).then((res) => res.json())
                .then((data) => {
                    setClientSecret(data.clientSecret)
                });
        }
        // if(localStorage.getItem('CMBMDT')===true){
        //     itemId = 3;
        // }
    }

    useEffect(() => {
        if (recordingTimer && firstName && currentEmail) {
            if (cardData == 'false') {
                //looged in flow
                createPaymentIntent();
            } else {
                createPaymentIntent();
            }
        } else if (recordingTimer) {
            //logged out flow
            createPaymentIntent();
        }
    }, [recordingTimer, cardData, firstName, currentEmail]);

    function getRecordingTimeFromQuery() {
        const recordingTime = getRecordingTimeFromLS();
        if (recordingTime) {
            setRecordingTimer(recordingTime);
        }
    }
    function uploadComplete(event) {
        if (event.videoLink) {
            setVideoLink(event.videoLink);
        }
        if (event.previewUrl) {
            setPreviewUrl(event.previewUrl);
        }
        if (event.liveStreamId) {
            setLiveStreamId(event.liveStreamId);
        }
        if (event.videoId) {
            setVideoId(event.videoId);
        }
        if (event.fileObj) {
            setFileObj(event.fileObj)
        }
    }
    function handleSendIt() {
        SetOpenDialog(false);
        setOpenCamiCodeDialog(true);
    }
    function uploadVideo(event) {
        setSelectPdf(false);
        const _subject = event['paymentDetails']['subject'] || '';
        const _vEmail = event['paymentDetails']['videoEmail'] || '';
        const _vName = event['paymentDetails']['videoName'] || '';
        setCamiTitle(_subject);
        SetIsUploading(true);
        uploadRecordedVideoBusiness(fileObj)
            .subscribe({
                next: (res) => {
                    if (res['event']) {
                        switch (res['event']) {
                            case 'progressbar':
                                if (res['progress'] == 100) {
                                    setPercentageUploaded(null);
                                } else {
                                    setPercentageUploaded(res['progress']);
                                }
                                break;
                            case 'complete':
                                setPercentageUploaded(null);
                                if (res['response']['videoId']) {
                                    setIsLoading(true);
                                    getDuration(res['response']['videoId'], event)
                                } else {
                                    console.log("Video ID not available");
                                }
                                break;
                            case 'error':
                                break;
                        }
                    }
                },
                error: (err) => {
                },
                complete: (comp) => { }
            });
    }
    function getDuration(_videoId, event) {
        getBearerToken()
            .then((response) => {
                getVideoStatus(response, _videoId)
                    .then((result) => {
                        mapCamitagForVideo(_videoId, event, result['encoding']['metadata']['duration'], result['ingest']['filesize']);
                    })
                    .catch((error) => {
                        console.log(error, 'err');
                    });
            })
            .catch((error) => {
                console.log(error, 'err');
            });
    }
    async function getCAMIsID(camiId) {
        const result = await getCamiCode(camiId, pass)
        setCamiTypeId(result?.camiTypeId)
        console.log(result?.camiTypeId)
        return result?.id;
    }
    async function mapCamitagForPdf(event) {
        setIsLoading(true)
        const _subject = event['paymentDetails']['subject'] || '';
        const _vEmail = event['paymentDetails']['videoEmail'] || '';
        const _vName = event['paymentDetails']['videoName'] || '';
        const auth = getFirebaseAuthModule();
        SetIsUploading(true)
        let authId = '';
        if (auth['currentUser']) {
            if (auth['currentUser']['uid']) {
                authId = auth['currentUser']['uid'];
            }
        }
        const camitagid = (localStorage.getItem('P')) ? (localStorage.getItem('P')) : ('');
        setCamiId(camitagid)
        localStorage.removeItem('P');
        const userData = (authId) ? (await getUserByAuthId(authId)) : ('');
        let userId = 0;
        if (!(userData['status'] === 'error')) {
            userId = userData['id'];
        }
        const data = {
            "camiTypeId": 0,
            "imageURI": "",
            "videoId": null,
            "duration": recordingTimer / 1000,
            "title": camiTitle,
            "subject": _subject,
            "camiCreatedBy": 0,
            "videoCreatedBy": userId,
            "accessRights": 0,
            "recordStatus": true,
            "vName": _vName,
            "vEmail": _vEmail,
            "isPaid": true,
            "isFree": false,
            "freeFor": 0,
            "createdBy": 0,
            "videoLength": null,
            "videoSize": null,
            "fileUrl": pdfURL.toString(),
            "fileSize": imageSize.toString()
        }
        if (camiId && paid == true || isFree) {
            const userData = (authId) ? (await getUserByAuthId(authId)) : ('');
            let userId = 0;
            if (!(userData['status'] === 'error')) {
                userId = userData['id'];
            }
            getCAMIsID(camiId).then((resp_camiID) => {
                const updateData = {
                    "id": resp_camiID,
                    "camiTypeId": camiTypeId,
                    "imageURI": "",
                    "videoId": null,
                    "duration": recordingTimer / 1000,
                    "title": camiTitle,
                    "description": _subject,
                    "camiCreatedBy": 0,
                    "videoCreatedBy": userId,
                    "accessRights": 0,
                    "recordStatus": true,
                    "vName": _vName,
                    "vEmail": _vEmail,
                    "isPaid": true,
                    "isFree": isFree,
                    "createdBy": 0,
                    "videoLength": null,
                    "videoSize": null,
                    "qrImage": "",
                    "fileUrl": pdfURL.toString(),
                    "fileSize": imageSize.toString()
                }
                updateData['camiCode'] = camiId;
                UpdateCAMIApi(updateData)
                    .then((result) => {
                        navigate("/camicard?c=" + camiId + '&p=' + pass + "&ps=success " + '&result=update_success');
                        localStorage.removeItem('IDOFCAMI')
                    })
                    .catch((error) => {
                        setAppState("APPROVED");
                        console.error(error);
                    });
            })
        } else {
            mapCamitagAndVideo(data)
                .then((result) => {
                    let camitagid = result.camiId || "";
                    let pass = result.camiPass || "";
                    navigate("/camicard?c=" + camitagid + '&p=' + pass + "&ps=success");
                })
                .catch((error) => {
                    setAppState("APPROVED");
                    console.error(error);
                });
        }
        setIsLoading(false)
        // localStorage.removeItem('CCRECORD');
    }


    async function mapCamitagForVideo(_videoId, event, _videoLength, _videoSize) {
        const _subject = event['paymentDetails']['subject'] || '';
        const _vEmail = event['paymentDetails']['videoEmail'] || '';
        const _vName = event['paymentDetails']['videoName'] || '';
        const auth = getFirebaseAuthModule();
        let authId = '';
        if (auth['currentUser']) {
            if (auth['currentUser']['uid']) {
                authId = auth['currentUser']['uid'];
            }
        }
        const camitagid = (localStorage.getItem('P')) ? (localStorage.getItem('P')) : ('');
        setCamiId(camitagid)
        // const uniqueId = localStorage.getItem('UNIQUEID')
        localStorage.removeItem('P');
        const userData = (authId) ? (await getUserByAuthId(authId)) : ('');
        let userId = 0;
        if (!(userData['status'] === 'error')) {
            userId = userData['id'];
        }
        const data = {
            "camiTypeId": camiTypeId,
            "imageURI": "",
            "videoId": _videoId,
            "duration": recordingTimer / 1000,
            "title": camiTitle,
            "subject": _subject,
            "camiCreatedBy": 0,
            "videoCreatedBy": userId,
            "accessRights": 0,
            "recordStatus": true,
            "vName": _vName,
            "vEmail": _vEmail,
            "isPaid": true,
            "isFree": false,
            "freeFor": 0,
            "createdBy": 0,
            "videoLength": videoDuration,
            "videoSize": videoSize.toString()
        }
        if (camiId && paid == true || isFree) {
            const userData = (authId) ? (await getUserByAuthId(authId)) : ('');
            let userId = 0;
            if (!(userData['status'] === 'error')) {
                userId = userData['id'];
            }
            getCAMIsID(camiId).then((resp_camiID) => {
                const updateData = {
                    "id": resp_camiID,
                    "camiTypeId": camiTypeId,
                    "imageURI": "",
                    "videoId": _videoId,
                    "duration": recordingTimer / 1000,
                    "title": camiTitle,
                    "description": _subject,
                    "camiCreatedBy": 0,
                    "videoCreatedBy": userId,
                    "accessRights": 0,
                    "recordStatus": true,
                    "vName": _vName,
                    "vEmail": _vEmail,
                    "isPaid": true,
                    "isFree": isFree,
                    "createdBy": 0,
                    "videoLength": videoDuration,
                    "videoSize": videoSize.toString(),
                    "qrImage": ""
                }
                updateData['camiCode'] = camiId;
                UpdateCAMIApi(updateData)
                    .then((result) => {
                        if(camiTypeId == 5){
                            setIsLoading(false)
                           setShowAlert(true)
                        }else{
                            navigate("/camicard?c=" + camiId + '&p=' + pass + "&ps=success " + '&result=update_success');
                            localStorage.removeItem('IDOFCAMI')
                        }
                       
                    })
                    .catch((error) => {
                        setAppState("APPROVED");
                        console.error(error);
                    });
            })
        } else {
            mapCamitagAndVideo(data)
                .then((result) => {
                    let camitagid = result.camiId || "";
                    let pass = result.camiPass || "";
                    navigate("/camicard?c=" + camitagid + '&p=' + pass + "&ps=success");
                })
                .catch((error) => {
                    setAppState("APPROVED");
                    console.error(error);
                });
               
        }
        setIsLoading(false)
        // localStorage.removeItem('CCRECORD');
    }
    const handleNavigate = () => {
        if (camiId && pass) {
            navigate('/create-cami?c=' + camiId + '&p=' + pass);
        } else {
            navigate('/create-cami')
        }
    }
    const handleChoose = (event) => {
        // localStorage.removeItem('RT');
        inputRef.current.click();
    };
    const handleChoosePdf = (event) => {
        UploadPdfRef.current.click();
    }
    function handleDialogClose() {
        setOpenModal(false);
        setSelectPdf(false);
        SetOpenDialog(true);
    }
    function handleCamiCodeDialogClose() { }
    function replayVideo() {
        setReplayStart(true);
        SetOpenDialog(false);
        setOpenModal(false);
        setSelectPdf(false);
        setIsPaused(false);
        VideoReplayRef.current.src = uploadURL;
        VideoReplayRef.current.autoPlay = false;
        VideoReplayRef.current.play();
    }
    function playPause() {
        VideoReplayRef.current.pause();
        setIsPaused(true)
        SetOpenDialog(true);
        if (isPaused == true) {
            VideoReplayRef.current.play();
            setIsPaused(!isPaused);
        }
    }


    function renderVideoPreview() {
        let videoPreviewStyle;
        if (smallDevices) {
            videoPreviewStyle = VideoPreviewStyleSM;
        } else {
            videoPreviewStyle = VideoPreviewStyleMD;
        }
        return <>
            <div style={videoUpload}>
                <video style={videoPreviewStyle} ref={VideoReplayRef} playsInline ></video>
                {/* { renderDialogOnPause()}  */}
                {/* <Button onClick={playPause }>Stop</Button> */}
                {replayStart == true && <Box sx={recordBtnContainerReplay}  >
                    <Box sx={recordBtnInnerContainer} >
                        <Fab sx={fabButton} onClick={playPause}>
                            {isPaused === true && <PlayArrowIcon sx={{ color: mainColor, fontSize: "40px" }} />}
                            {isPaused === false && <StopCircle sx={{ color: mainColor, fontSize: "40px" }} />}
                        </Fab>
                    </Box>
                </Box>}
            </div>
        </>
    }


    function renderPdfPreview() {
        if (smallDevices) {
            return <>
                <div className="Example__container__document" >
                    <center>
                        <div style={{ paddingTop: "10px" }} >
                            {loading == true && circularIndeterminate()}
                            {loading == false && <Loader> </Loader>}
                            <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess} >
                                <Page height="500" pageNumber={pageNumber} >
                                    <Box sx={{ display: "flex", justifyContent: "space-evenly" }} >
                                        <IconButton
                                            aria-label="close"
                                            onClick={changePageBack}
                                            sx={{
                                                color: mainColor
                                            }}
                                        >
                                            <KeyboardDoubleArrowLeftIcon />
                                        </IconButton>
                                        <p style={{ color: mainColor }}>Page {pageNumber} of {numPages} </p>
                                        <IconButton
                                            aria-label="close"
                                            onClick={changePageNext}
                                            sx={{
                                                color: mainColor
                                            }}
                                        >
                                            <KeyboardDoubleArrowRightIcon />
                                        </IconButton>
                                    </Box>
                                </Page>
                                <Box sx={{ display: "flex", justifyContent: "space-evenly",marginTop: "10px" }} >
                                    <CustomButton sx={{ borderRadius: 20, paddingRight: "25px", paddingLeft: "25px",marginRight: "10px"}} varint={'contained'} onClick={handleCancelModal} >CANCEL</CustomButton>
                                    <CustomButton sx={{ borderRadius: 10 ,marginRight: "-32px"}} varint={'contained'} onClick={handleSendIt} >MAKE IT LIVE!</CustomButton>
                                </Box>
                            </Document>
                        </div>
                    </center>
                </div>
            </>
        } else {
            return <>
                <div className="Example__container__document"  >
                    <center>
                        <div style={{ paddingTop: "10px" }}>
                            {loading == true && circularIndeterminate()}
                            {loading == false && <Loader></Loader>}
                            <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page height="600" pageNumber={pageNumber}>
                                    <Box sx={{ display: "flex", justifyContent: "space-evenly" }} >
                                        <IconButton
                                            aria-label="close"
                                            onClick={changePageBack}
                                            sx={{
                                                color: mainColor
                                            }}
                                        >
                                            <KeyboardDoubleArrowLeftIcon />
                                        </IconButton>
                                        <p style={{ color: mainColor }}>Page {pageNumber} of {numPages} </p>
                                        <IconButton
                                            aria-label="close"
                                            onClick={changePageNext}
                                            sx={{
                                                color: mainColor
                                            }}
                                        >
                                            <KeyboardDoubleArrowRightIcon />
                                        </IconButton>
                                    </Box>
                                </Page>
                                <Box sx={{ display: "flex", justifyContent: "space-evenly", marginBottom: "50px" }} >
                                    <CustomButton sx={{ borderRadius: 20, paddingRight: "25px", paddingLeft: "25px"}} varint={'contained'} onClick={handleCancelModal} >CANCEL</CustomButton>
                                    <CustomButton sx={{ borderRadius: 10 ,marginLeft:"-335px"}} varint={'contained'} onClick={handleSendIt} >MAKE IT LIVE!</CustomButton>
                                </Box>
                            </Document>
                        </div>
                    </center>
                </div>
            </>
        }
    }

    function renderImagePreview() {
        let videoPreviewStyle;
        if (smallDevices) {
            videoPreviewStyle = ImagePreviewStyleSM;
        } else {
            videoPreviewStyle = ImagePreviewStyleMD;
        }
        return <>
            {loading == true && circularIndeterminate()}
            {loading == false && <div>
                <div style={videoUpload} >
                    {imageUpload && <img style={videoPreviewStyle} src={imageUpload} ref={imageUploader}  >
                    </img>}
                </div>
                <Box sx={{ display: "flex", justifyContent: "space-evenly",marginTop: "10px" }} >
                    <CustomButton sx={{ borderRadius: 20, paddingRight: "25px", paddingLeft: "25px" }} varint={'contained'} onClick={handleCancelModal} >CANCEL</CustomButton>
                    <CustomButton sx={{ borderRadius: 20 }} varint={'contained'} onClick={handleSendIt} >MAKE IT LIVE!</CustomButton>
                </Box>
            </div>}
        </>
    }
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress color="secondary" variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 37 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }
    function uploadProgressComponent() {
        return (<Dialog fullWidth={true}
            sx={{ zIndex: 1235 }}
            onClose={handleDialogClose}
            open={((Math.floor(percentageUploaded) > 0 && (Math.floor(percentageUploaded) < 100)))} >
            <Box sx={{ width: '100%' }} >
                <LinearProgressWithLabel value={percentageUploaded} />
            </Box>
        </Dialog>);
    }
    function reUploadVideo() {
        SetOpenDialog(false);
        setOpenModal(true);
    }
    const handleClickOpenModal = () => {
        setOpenModal(true);
    };
    function handleCancelModal() {
        setOpenModal(false);
        navigate('/cami-steps');
        localStorage.removeItem('RT');
    };
    const handleClickAlert = () => {
        setIsSubmit(true);
    };
    const handleCloseAlert = () => {
        setIsSubmit(false);
    };
    const handleAlertClose = () => {
        setOpenAlert(true)
    }
    const handleIconClose = () => {
        setOpenAlert(false)
    }
    const alertDialog = () => {
        if (openAlert === true) {
            return (
                <Dialog onClose={handleAlertClose} open={openAlert}  >
                    <Alert onClose={handleIconClose} severity="error" sx={{ width: '100%' }}>
                        video length should be  less than or equal to {recordingTimer / 1000} sec
                    </Alert>
                </Dialog>
            );
        }
    }
    const [alertMsg, setAlertMsg] = useState(false);
    const handleAlertCloseMsg = () => {
        setAlertMsg(false);
        selectPdf(true);
    }
    const alertDialogPdf = () => {
        if (alertMsg === true) {
            return (
                <Dialog onClose={handleAlertCloseMsg} open={alertMsg}  >
                    <Alert onClose={handleAlertCloseMsg} severity="error" sx={{ width: '100%' }}>
                        image/pdf is less then or equal to 70MB
                    </Alert>
                </Dialog>
            );
        }
    }


    function renderConfirmationDialog() {
        if (!isUploading) {
            return (
                <Dialog onClose={handleDialogClose} open={openDialog}  >
                    <DialogTitle sx={{ color: background.backgroundColor, fontSize: '1rem' }} >Happy with your message?</DialogTitle>
                    <Box sx={{ display: 'block', width: '100%', alignItems: 'center', paddingBottom: 1 }} >
                        <CustomButton sx={dialogButtons} variant={'contained'} onClick={replayVideo} >REPLAY</CustomButton>
                        <CustomButton sx={dialogButtons} varint={'contained'} onClick={handleSendIt} >MAKE IT LIVE!</CustomButton>
                    </Box>
                </Dialog>
            )
        }
    }

    const [selectPdf, setSelectPdf] = useState(false)
    const handleClickOpenPdf = () => {
        setSelectPdf(true);
    }
    const uploadedImage = useRef(null);
    const imageUploader = useRef(null);
    const [imageUpload, setImageUpload] = useState(null);
    const [imageSize, setImageSize] = useState('')
    const [pdfURL, setPdfURL] = useState('')
    const [imageFormat, setImageFormat] = useState(false)
  
    const handleImageUpload = e => {
        let file = e.target.files[0];
        const fileURL = URL.createObjectURL(file);
        setImageUpload(fileURL);
        setSelectPdf(false)
        let path = file['name'];
        setImageSize(file['size'])
        setImageFormat(file['type']);
        const reader = new FileReader();
        const data = reader.readAsDataURL(file);
        reader.addEventListener('load', (e) => {
            const data = e.target.result;
        })
        const image = document.createElement('image');
        image.src = URL.createObjectURL(file);
        localStorage.setItem("IMAGE", image.src)
        setLoading(true);
        localStorage.removeItem('RT');
        uploadPdfImage(file, path)
            .then((response) => {
                setPdfURL(response)
                setLoading(false);
                localStorage.setItem("PDFURL", response)
                uploadComplete({
                    "previewUrl": response,
                    "liveStreamId": '',
                    "state": "PREVIEW",
                    "fileObj": file
                })
            }).catch((error) => {
                console.log((error));
            })
        if (file.size <= 7000000) {
            console.log(file.size <= 7000000, 'size', file.size);
        } else {
            SetOpenDialog(false)
            setAlertMsg(true);
            setSelectPdf(true)
        }
    };
    const handleFileChoose= (e) => {
        uploadedImage.current.click();

    }
    const uploadDialog = () => {
       if( recordingTimer == 29000 || payOption == "P1"){
        return (
            <Dialog onClose={handleClickOpenPdf} open={selectPdf} sx={{ minWidth: "max-content" }}>
                <DialogTitle sx={{ paddingLeft: "100px", paddingRight: "100px", paddingTop: "0px" }} ></DialogTitle>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCancelModal}
                        sx={{
                            position: 'absolute',
                            top: -4,
                            marginRight: "156px",
                            color: mainColor,
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ display: 'block', alignItems: 'center', paddingBottom: 1, width: '180px', paddingTop: 2 }} >
                        <CustomButton sx={dialogButton} variant={'contained'} onClick={handleNavigate}>RECORD VIDEO</CustomButton>
                        <input
                            ref={inputRef}
                            type="file"
                            onChange={handleFileChange}
                            accept=".mov,.mp4"
                            style={{
                                display: "none"
                            }}
                        />
                        {!source && <CustomButton sx={dialogButton} variant={'contained'} onClick={handleChoose}  >UPLOAD VIDEO</CustomButton>
                        }
                        <input
                            type="file"
                            accept="image/png,.pdf,.doc,image/jpg,image/heif,image/gif"
                            onChange={handleImageUpload}
                            ref={uploadedImage}
                            style={{
                                display: "none"
                            }}
                        />
               <CustomButton sx={dialogButton} variant={'contained'} onClick={handleFileChoose}  >UPLOAD  PDF/Image</CustomButton>
    
                    </Box>
                </Box>
            </Dialog>
        )
}
else{
    return(
    <Dialog onClose={handleClickOpenPdf} open={selectPdf} sx={{ minWidth: "max-content" }}   >
                <DialogTitle sx={{ paddingLeft: "100px", paddingRight: "100px", paddingTop: "0px" }} ></DialogTitle>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCancelModal}
                        sx={{
                            position: 'absolute',
                            top: -4,
                            marginRight: "156px",
                            color: mainColor,
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ display: 'block', alignItems: 'center', paddingBottom: 1, width: '180px', paddingTop: 2 }} >
                        <CustomButton sx={dialogButton} variant={'contained'} onClick={handleNavigate}>RECORD VIDEO</CustomButton>
                        <input
                            ref={inputRef}
                            type="file"
                            onChange={handleFileChange}
                            accept=".mov,.mp4"
                            style={{
                                display: "none"
                            }}
                        />
                        {!source && <CustomButton sx={dialogButton} variant={'contained'} onClick={handleChoose}  >UPLOAD VIDEO</CustomButton>
                        }
                        <input
                            type="file"
                            accept="image/png,.pdf,.doc,image/jpg,image/heif,image/gif"
                            onChange={handleImageUpload}
                            ref={uploadedImage}
                            style={{
                                display: "none"
                            }}
                        />
                    </Box>
                </Box>
            </Dialog>
    )
}
    }

   function  handleSucessClose(){
        navigate("/forever-cami")
    }

    useEffect(() => {
        const paid = (localStorage.getItem('P')) ? (localStorage.getItem('P')) : ('');
        // if (camitagid){}
        // setCamiId(paid)
        // const uniqueId = localStorage.getItem('UNIQUEID')
        localStorage.removeItem('P');
    }, [])
    function displayProgress() {
        const percentageTrue = ((Math.floor(percentageUploaded) > 0 && (Math.floor(percentageUploaded) < 100)))
        if (percentageTrue == true) {
            return (<Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh"
            }} >
                <CircularProgressWithLabel sx={{ color: mainColor }} value={percentageUploaded} />
            </Box>)
        }
    }
    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress size={70} variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography sx={{ fontSize: "22px" }} variant="caption" component="div" color="black">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }
    CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired,
    };

    function paymentStatus(event) {
        setOpenCamiCodeDialog(false);
        if (imageFormat == "image/png" || imageFormat == "image/jpg" || imageFormat == "image/heif" || imageFormat == "image/gif" || imageFormat == "application/pdf" || imageFormat == "image/jpeg" || imageFormat == "image/heic") {
            mapCamitagForPdf(event);
        } else {
            uploadVideo(event);
        }
        setOpenModal(false);
    }
    function handleSendIt() {
        SetOpenDialog(false);
        setOpenCamiCodeDialog(true);
    }
    function handleCamiCodeDialogClose() { }
    function handleRecordingOptionsDialog() {
    }
    function recordAndPayOptions() {
        return (<Dialog
            fullWidth={true}
            onClose={handleCamiCodeDialogClose}
            maxWidth={'xs'}
            scroll={'body'}
            open={openCamiCodeDialog} >
            <DialogTitle sx={{ textAlign: "center" }}>
                CAMIcode Details
            </DialogTitle>
            <Box sx={paddingPurchaseLeft}>
                {clientSecret && (<Elements stripe={stripePromise} options={stripe_options} >
                    <CheckoutForm paymentStatus={paymentStatus} paid={paid} card={cardData} itemId={theItemID} secret={clientSecret} />
                </Elements>)}
            </Box>
        </Dialog>);
    }
    if (isLoading == true) {
        return (<Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} >
            <Box sx={{ marginBottom: 3, width: '100%' }} >
                <CircularProgress sx={{ margin: '0 auto' }} />
            </Box>
            <Typography sx={{ fontSize: "35px", color: mainColor }}>Your CAMIcode is getting Generated!</Typography>
        </Box>)
    } else if (dialogLoading == true){
        return(
        <Box sx={{ textAlign: "center" }}>
        <CircularProgress
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            marginBottom: 3,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Box>
        )
    } else if(showAlert == true){
      return (
        <Box>
        <Box  sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "60vh", maxWidth: "100%", }}>
          <Alert onClose={handleSucessClose} severity="success">
          Your Video is Uploaded successfully.
          </Alert>
        </Box>
      </Box>
      );
    }
    else return (
        <>
            <div style={(imageFormat == "image/png" || imageFormat == "image/jpg" || imageFormat == "image/heif" || imageFormat == "image/gif" || imageFormat == "image/jpeg" || imageFormat == "image/heic") ? {} : hide}>
                {!isUploading && renderImagePreview()}
            </div>
            <div style={(imageFormat == "application/pdf") ? {} : hide}>
                {!isUploading && renderPdfPreview()}
            </div>
            <div style={(imageFormat != "image/png" && imageFormat != "image/jpg" && imageFormat != "image/heif" && imageFormat != "application/pdf" && imageFormat != "image/gif" && imageFormat != "image/jpeg" && imageFormat != "image/heic") ? {} : hide}>
                {!isUploading && renderVideoPreview()}
            </div>
            {alertDialog()}
            {uploadDialog()}
            {alertDialogPdf()}
            {recordAndPayOptions()}
            {displayProgress()}
            {renderConfirmationDialog()}
        </>
    );
}