import { Box, Button, CircularProgress, useMediaQuery, Fab, useTheme, Dialog, DialogTitle, Alert } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, Navigate, useLocation, } from 'react-router-dom';
import { getCamitagFromUrl, getQueryParamValue, getVideoAvailabilityStatus } from '../../Business/MediaBusiness';
import Loader from '../Components/Loader';
import IconButton from '@mui/material/IconButton';
import PauseIcon from '@mui/icons-material/Pause';
import { duration, keyframes, styled } from "@mui/material/styles";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { CoPresent, Event } from '@mui/icons-material';
import { updateCardDetails, UpdateViewCount, viewCountDetails, createLogDetails, getUserByAuthId, Updatectacount, UpdatectacountLocation, findlocationmapping } from '../../Repository/Api';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Hls from 'hls.js';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { red } from '@mui/material/colors';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export default function Watch() {
    const navigate = useNavigate();
    const theme = useTheme();
    const smallDevices = useMediaQuery(theme.breakpoints.down('md'));
    const azureMediaPlayerRef = useRef();
    const _location = useLocation();
    let [camitagid, setCamitagId] = useState(null);
    let [videoId, setVideoId] = useState(null);
    let [appState, setAppState] = useState("IDLE");
    let [playerState, setPlayerState] = useState("READY");
    let [previewPlaying, setPreviewPlaying] = useState(false);
    let [videoAvailability, setVideoAvailability] = useState('scan');
    let [apiResult, setApiResult] = useState(null);
    let [camiIdLocalStorage, setCamiIdLocalStorage] = useState("")
    let [isPaused, setIsPaused] = useState(true);
    let [openDialog, SetOpenDialog] = useState(false);
    let [donePlaying, setDonePlaying] = useState(false);
    const [videoProgress, setVideoProgress] = useState("")
    const [playerProgress, setPlayerProgress] = useState(100)
    const [showProgress, setShowProgress] = useState(false);
    const [emailFromUrl, setEmailFromUrl] = useState("");
    const [codeFromUrl, setCodeFromUrl] = useState("");
    const [returnPath, setReturnPath] = useState('');
    const [pass, setPass] = useState('');
    const [emailFromLocal, setEmailFromLocal] = useState('');
    const [password, setPassword] = useState('');
    const [camiId, setCamiId] = useState('');
    const [loggedUser, setLoggedUser] = useState('');
    let [fileUrl, setFileUrl] = useState(null);
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    // const [viewPdf, setViewPdf] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [imageType, setImageType] = useState('')
    const [scale, setScale] = useState(1.0);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [camiCode, setCamiCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [ctaLink, setctaLink] = useState(null);
    const [ctaTopic, setctaTopic] = useState("");
    const [rendercta, setrendercta] = useState(false);
    const [videoLoading, setvideoLoading] = useState(false);
    const [camiType, setCamiType] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [loginLink, setLoginLink] = useState(false);
    const [user, setUser] = useState("");
    const [foreverCami, setForeverCami] = useState(0);
    const [loguser, setLoguser] = useState(false);
    const [locationData, setLocationData] = useState(null);

    const hide = {
        display: "none"
    }

    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offSet) {
        setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }

    function changePageBack() {
        changePage(-1)
    }

    function changePageNext() {
        changePage(+1)
    }

    const videoRef = useRef(null);
    const hls = useRef(null);

    function circularIndeterminate() {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }


    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user)
                getUserEmailFromUid(user.uid);
            } else {
                setUser("")
                setLoguser(true)
            }
        })
    }, [])

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            if (Hls.isSupported()) {
                hls.current = new Hls({
                    startLevel: 0, // Set the initial quality level (0 for the lowest quality)
                });

                hls.current.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    console.log("Manifest parsed, found " + data.levels.length + " quality level(s)");
                });

                hls.current.on(Hls.Events.ERROR, function (event, data) {
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                break;
                            default:
                                break;
                        }
                    }
                });
                hls.current.loadSource('https://vod.api.video/vod/' + videoId + '/hls/manifest.m3u8');
                hls.current.attachMedia(video);
            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = 'https://vod.api.video/vod/' + videoId + '/hls/manifest.m3u8';
            }
        }
    }, [videoId]);

    useEffect(() => {
        if (navigator.geolocation && camiCode != '') {
            const handlePosition = (position) => {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                function findlocation() {
                    const _latlong = {
                        camicode: camiCode,
                        latitude: latitude,
                        longitude: longitude,
                    };
                    findlocationmapping(_latlong)
                        .then((response) => {
                            setLocationData(response);
                        }).catch((error) => {
                            // setCardData("false")
                        })
                }
                findlocation()
            }
            navigator.geolocation.getCurrentPosition(handlePosition);
        }
    }, [camiCode])

    const handleProgress = (event) => {
        const video = event.target;
        if (video && video.readyState < 4 && !video.paused) {
            setvideoLoading(true);
        } else {
            setvideoLoading(false);
        }
    };

    const handlePlaying = (event) => {
        setvideoLoading(false);
    };

    const handleWaiting = (event) => {
        setvideoLoading(true)
    }

    const mainColor = "#981D97";
    const localCamiId = localStorage.getItem("theCamiId");
    const recordBtnContainerReplay = {
        position: 'absolute',
        bottom: '3px',
        zIndex: '1051',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: smallDevices ? ('100vw') : ('50vw'),
        height: '64px',
        '@media (max-width: 600px)': {
            bottom: '5%',
        },
    };

    const recordBtnInnerContainer = {
        m: 1,
        position: 'relative',
        margin: '0 auto',
        height: '100%'
    };

    const fabButton = {
        position: 'absolute',
        left: '-28px',
        top: '0px',
        border: "4px solid",
        borderColor: "transparent",
        borderRadius: "50%",
        backgroundColor: "transparent",
        zIndex: "1050",
        "&:hover": {
            backgroundColor: "transparent",
        },
        //    textAlign: "center",
        //     lineHeight: "100px"
    }


    function viewCount() {
        UpdateViewCount(camiId, loggedUser)
            .then((response) => {
                const _getViewDetail = {
                    "cami": camiCode,
                    "email": email || "",
                    "createdBy": loggedUser,
                    "recordStatus": true
                }
                viewCountDetails(_getViewDetail)
                    .then((response) => {
                        console.log(response, 'res in count details');
                    })
                    .catch((error) => {
                        console.log(error, 'err');
                    });
            })
            .catch((error) => {
                console.log(error, 'err');
            });
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
        setIsLoading(false);
    }

    function get_url_extension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }


    useEffect(componentWillMount, []);
    useEffect(() => {
        if (loguser) {
            camitagidSideEffect()
        }
    }, [camitagid, password, loguser, foreverCami])




    useEffect(() => {
        if (camiId, loggedUser, email) {
            viewCount();
        }
    }, [camiId, loggedUser, email])

    function componentWillMount() {
        if (localCamiId) {
            const _camitagid = localCamiId
            setCamitagId(_camitagid);
            localStorage.removeItem('theCamiId');
            return componentWillUnMount;
        } else {
            const _camitagid = getCamitagFromUrl();
            setCamitagId(_camitagid);
            return componentWillUnMount;
        }
    }
    function componentWillUnMount() {

    }


    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    getUserEmailFromUid(user.uid);
                    // setCurrentEmail(user.email);
                });
            } else {
                viewCount();
                return;
            }
        }
        );
        // SetUpdateDone(false)
    }, [camiId, loggedUser])


    const getUserEmailFromUid = async (authId) => {
        const response = await getUserByAuthId(authId)
            .then(response => {
                setForeverCami(response.foreverCamiCodes)
                setEmail(response.email);
                setLoguser(true);
            }).catch(error => console.log(error, "error receiving data by email"))
    }

    function getRedirectionPathFromUrl() {
        const urlSearchParams = new URLSearchParams(window.location.search.toString().slice(1));
        if (urlSearchParams.has('redir')) {
            const redirPath = urlSearchParams.get('redir').replace(/ /g, '');
            const decodedPathName = decodeURI(redirPath);
            setReturnPath(decodedPathName);
        }
        else if (!urlSearchParams.has('redir')) {
            setReturnPath('/camicard');
        }
        else if (urlSearchParams.has('p')) {
            const _pass = urlSearchParams.get('p').replace(/ /g, '');
            setPass(_pass);
        }
        else if (urlSearchParams.has('e')) {
            const emailOfReceiver = urlSearchParams.get('e').replace(/ /g, '');
            setEmailFromUrl(emailOfReceiver);
        }
    }

    const goToCamiCard = (navigate, camiCardId, password) => {
        navigate({
            pathname: returnPath,
            search: `?c=${camiCardId}`,
        });
        if (emailFromUrl && camiCardId) {
            navigate({
                pathname: returnPath,
                search: `?c=${camiCardId}&e=${emailFromUrl}`,
            });
        }
        if (password && camiCardId) {
            navigate({
                pathname: returnPath,
                search: `?c=${camiCardId}&p=${password}`,
            });
        }
        if (password && camiCardId && emailFromUrl) {
            navigate({
                pathname: returnPath,
                search: `?c=${camiCardId}&p=${password}&e=${emailFromUrl}`,
            });
        }
        // const urlParams = new URLSearchParams(window.location.search);
        //     const goBackTo = urlParams.get('f') || "";
        //    
        //    if (goBackTo == "c" ){
        //  const url = 'camicard?c='+ "check"

        //    }
        // if(localStorage.getItem('RCP')){
        //     const returnPath = localStorage.getItem('RETURNPATH')
        //     const receivedCami = localStorage.getItem('RECEIVEDCAMI')
        //     const takeBackToUrl = returnPath + receivedCami
        //     navigate(takeBackToUrl);
        // }
        // else if(localStorage.getItem(localStorage.getItem('CCP'))){ 
        //     const returnPath = localStorage.getItem('RETURNPATH')
        //     const createdCami = localStorage.getItem('CREATEDCAMI')
        //     const takeBackToUrl = returnPath + createdCami
        //     navigate(takeBackToUrl);
        // }
    }

    function getCurrentUrl() {
        const url = window.location;
        if (url) {
            const localurlparams = new URLSearchParams(url.search.toString());
            if (localurlparams.has('p')) {
                const passFromUrl = localurlparams.get('p').replace(/ /g, '');
                setPassword(passFromUrl)
                // localStorage.setItem('EMAILFROMURL', emailFromUrl)
            }
            //         const redirectionURL = (emailFromUrl) ? (getLocalPath + '?c=' + getLocalUrlCamiID + '&e=' + emailFromUrl) : (getLocalPath + '?c=' + getLocalUrlCamiID);


            //         navigate(redirectionURL);
            //         localStorage.removeItem('RETURNURL')
            //         localStorage.removeItem('RETURNPATH')
            //         localStorage.removeItem('CAMIID')
        }
    }



    useEffect(() => {
        // getReceiverEmailFromUrl();
        getRedirectionPathFromUrl();
        getCurrentUrl()
    }, [])


    function camitagidSideEffect() {
        if (camitagid && password) {
            getVideoAvailabilityStatus(camitagid, password)
                .then((res) => {
                    setCamiId(res.id);
                    setCamiType(res["camiTypeId"]);
                    setCamiCode(res.camiCode)
                    setAppState("DONE");
                    setCamiId(res["id"]);
                    setLoggedUser(res["videoCreatedBy"]);
                    setctaLink(res["ctaUrl"]);
                    setctaTopic(res["ctaTitle"]);
                    const _videoDuration = res['duration'];
                    const _video = res[""]
                    if (_videoDuration == "29") {
                        localStorage.setItem('RT', 'P1');
                        {/* localStorage.removeItem('RT'); */ }
                    } else if (_videoDuration == "180") {
                        localStorage.setItem('RT', 'P2');
                        {/* localStorage.removeItem('RT'); */ }
                    }
                    if (res['status'] === 'processing') {
                        setVideoAvailability('processing');
                    }
                    else if (res['status'] === 'ready') {
                        if (res['videoId']) {
                            setVideoAvailability('ready');
                            setVideoId(res['videoId']);
                            setFileUrl(res['fileUrl']);
                            const fileType = get_url_extension(res['fileUrl']);
                            setImageType(fileType.toLowerCase())
                        } else if (res['fileUrl']) {
                            setVideoAvailability('ready');
                            setFileUrl(res['fileUrl']);
                            const fileType = get_url_extension(res['fileUrl']);
                            setImageType(fileType.toLowerCase())
                        } else {
                            setVideoAvailability('processing');
                        }
                    } else if (res['status'] === 'navigate-camitagid' || res['status'] === 'navigate-fresh') {
                        if (res["camiTypeId"] == 5) {
                            if (!_video) {
                                if (user) {
                                    if (foreverCami > 0) {
                                        if (res["foreverEmail"] == user.email) {
                                            navigate("/upload-video?c=" + camitagid + '&p=' + password);
                                        } else {
                                            setShowAlert(true)
                                        }
                                    } else {
                                        setShowAlert(true)
                                    }
                                } else {
                                    setShowAlert(true);
                                    setLoginLink(true);
                                }
                            }
                        }
                        else if (localStorage.getItem('RT') == 'P1') {
                            navigate("/upload-video?c=" + camitagid + '&p=' + password);
                        } else {
                            navigate("/upload-video?c=" + camitagid + '&p=' + password);
                        }
                    }
                })
                .catch((err) => { });
        }
    }

    const replayPreview = () => {
        videoRef.current.play();
    }

    const playPauseControl = () => {
        if (isPaused == false) {
            videoRef.current.pause();
            setIsPaused(true);
            setvideoLoading(false)
        } else if (isPaused == true) {
            videoRef.current.play();
            getCurrentTimeUpdate();
            setIsPaused(false);
        }
    }


    function replayVideo() {
        SetOpenDialog(false);
        setrendercta(false);
        videoRef.current.play();
        setIsPaused(false);
    }

    function previewPlayerEventListener() {
        if (imageType != "png" && imageType != "jpg" && imageType != "heif" && imageType != "heic" && imageType != "jpeg" && imageType != "pdf" && imageType != "gif") {
            videoRef.current.addEventListener('ended', (event) => {
                setIsPaused(true);
                SetOpenDialog(true);
            });
        }
    }

    const circularProgress = {
        color: mainColor,
        position: 'absolute',
        zIndex: 1200,
        left: '-7px',
        top: '-7px',
        // position: 'absolute',
        // left: '-28px',
        // top: '0px',
        transform: "rotateY(180deg) rotateZ(-90deg)  !important",
    };

    function getCurrentTimeUpdate() {
        videoRef.current.addEventListener('timeupdate', (event) => {
            const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
            setPlayerProgress((100) - progress);
        })
    }

    function onTimeUpdate() {
        const currentTime = videoRef.current.currentTime
        if (currentTime > 3) {
            setrendercta(true)
        } else {
            setrendercta(false)
        }
    }

    useEffect(() => {
        if (imageType != "png" && imageType != "jpg" && imageType != "heif" && imageType != "heic" && imageType != "jpeg" && imageType != "pdf" && imageType != "gif") {
            if (videoAvailability === 'ready') {
                previewPlayerEventListener()
            }
        }
    }, [videoAvailability]);

    const loaderIcon = () => {
        if (appState === "LOADING") {
            return (
                <div className="D loadericon-watch" >
                    <Loader />
                    <div>Loading...</div>
                </div>
            );
        } else {
            return null;
        }
    }
    const playButtonOnVideo = () => {
        if (appState === "DONE" && !previewPlaying) {
            return (
                <button className="btn_play material-icons" onClick={replayPreview} >play_circle_filled</button>
            )
        }
    }

    const closeButton = () => {
        if (playerState === "READY") {
            return <Link to="/" style={{ display: 'block', textDecoration: 'none' }} ><button className="btn w50" >Close</button></Link>;
        }
    }
    const renderActionBarSection = () => {
        if (appState === "DONE") {
            return (
                <>
                    <div className="action-bar" >
                        {/* {pauseButton()}
                        {playButton()} */}
                        {closeButton()}
                    </div>
                </>
            );
        }
    }



    function handleCloseAlert() {
        navigate("/cami-steps")
    }
    function handleDialogClose() {
        SetOpenDialog(true)
    }

    function onTimeUpdate() {
        const currentTime = videoRef.current.currentTime
        if (currentTime > 3) {
            setrendercta(true)
        } else {
            setrendercta(false)
        }
    }
    function viewCamiCode() {
        const returnToLocation = localStorage.getItem('RETURNURL');
    }

    const dialogButtons = {
        display: 'block',
        margin: '10px auto',
        width: '100%',
        borderRadius: 20,
        width: "100%",
    }

    const calltoaction = {
        position: 'absolute',
        bottom: '66px',
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%,-50%)",
        zIndex: '1051',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: "2px solid",
        borderColor: mainColor,
        borderRadius: "20px 20px",
        padding: "1px 8px 1px 8px",
        backdropfilter: "blur(30px)",
        transition: "all .3s ease",
        borderRightWidth: "2px",
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#981d97",
            boxshadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        },
        '@media (max-width: 1024px) and (min-width: 601px)': {
            bottom: '22%',
        },
        '@media (max-width: 600px)': {
            bottom: '20%',
        },
    }

    const calltoactionImage = {
        position: 'absolute',
        bottom: '110px',
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%,-50%)",
        zIndex: '1051',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: "2px solid",
        borderColor: mainColor,
        borderRadius: "20px 20px",
        padding: "1px 8px 1px 8px",
        backdropfilter: "blur(30px)",
        transition: "all .3s ease",
        borderRightWidth: "2px",
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#981d97",
            boxshadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        },
        '@media (max-width: 1024px) and (min-width: 902px)': {
            bottom: '15%',
        },
        '@media (max-width: 901px)': {
            bottom: '30%',
        },
    }

    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#000"),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));

    const videoUpload = {
        display: "flex",
        justifyContent: "center",
        // padding: "65px"
    }



    const renderDialog = () => {
        if (camiType != 5) {
            return (
                <Dialog onClose={handleDialogClose} open={openDialog} sx={{ minWidth: "max-content" }}   >
                    <DialogTitle sx={{ paddingLeft: "100px", paddingRight: "100px", paddingTop: "0px" }} ></DialogTitle>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: 'block', alignItems: 'center', paddingBottom: 1, width: '180px' }} >
                            <CustomButton sx={dialogButtons} variant={'contained'} onClick={() => goToCamiCard(navigate, camitagid, password)} >VIEW CAMICODE</CustomButton>
                            <CustomButton sx={dialogButtons} variant={'contained'} onClick={replayVideo} >PLAY AGAIN</CustomButton>
                        </Box>
                    </Box>
                </Dialog>
            )
        }
    }

    const videoload = {
        position: 'absolute',
        bottom: '40%',
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%,-50%)",
        zIndex: '1051',
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    }


    const videoloading = () => {
        if (videoLoading) {
            return (
                <Box sx={videoload}>
                    <CircularProgress />
                </Box>
            );
        }
    }

    const renderPlayPauseButton = () => {
        if (imageType != "png" && imageType != "jpg" && imageType != "heif" && imageType != "heic" && imageType != "jpeg" && imageType != "pdf" && imageType != "gif") {
            return <>
                {<Box sx={recordBtnContainerReplay}  >
                    <Box sx={recordBtnInnerContainer} >
                        {!showProgress == true && <Fab sx={fabButton} onClick={playPauseControl}>
                            {isPaused === true && <PlayArrowIcon sx={{ color: mainColor, fontSize: "40px" }} />}
                            {isPaused === false && <PauseIcon sx={{ color: mainColor, fontSize: "40px" }} />
                            }
                            {<CircularProgress size={62} sx={circularProgress} variant="determinate" value={playerProgress} />}
                        </Fab>}
                    </Box>
                </Box>}
            </>
        }
    }

    const callToAction = () => {
        if (rendercta) {
            if (ctaLink == null) {
                return <></>
            } else {
                return <>
                    <Box sx={calltoaction}>
                        <a style={{ color: "white", textTransform: "uppercase", textDecoration: "none", fontSize: "12px" }} href={ctaLink} onClick={clickcount} target="_blank">{(ctaLink != null) ? (ctaTopic) : ("Learn More")}</a>
                    </Box>
                </>
            }
        }
    }
    const callToImgAction = () => {
        if (ctaLink == null) {
            return <></>
        } else {
            return <>
                <Box sx={calltoactionImage}>
                    <a style={{ color: "white", textTransform: "uppercase", textDecoration: "none", fontSize: "12px" }} href={ctaLink} onClick={clickcount} target="_blank">{(ctaLink != null) ? (ctaTopic) : ("Learn More")}</a>
                </Box>
            </>
        }
    }

    const videoReadyView = () => {
        if (smallDevices) {
            return <>
                <div style={videoUpload}>
                    <video playsInline onTimeUpdate={onTimeUpdate} onWaiting={handleWaiting} onPlaying={handlePlaying}
                        src={'https://cdn.api.video/vod/' + videoId + '/mp4/source.mp4#t=0.001'} style={{ width: '100vw', height: '100vh', playsinline: true, objectFit: 'contain', backgroundColor: "transparent" }} ref={videoRef}></video>
                </div>
                {renderPlayPauseButton()}
                {callToAction()}
                {videoloading()}
                {renderDialog()}

            </>
        } else {
            return <>
                <div style={videoUpload}>
                    <video onTimeUpdate={onTimeUpdate} onWaiting={handleWaiting} onPlaying={handlePlaying} src={'https://cdn.api.video/vod/' + videoId + '/mp4/source.mp4#t=0.001'} style={{ width: '800px', height: 'calc(100vh - 71px)', objectFit: 'contain', backgroundColor: "transparent" }} ref={videoRef}></video>
                    {/* <video src={'https://cdn.api.video/vod/' + videoId + '/mp4/source.mp4#t=0.001'} style={{ width: '800px', height: 'calc(100vh - 71px)', objectFit: 'contain',background:"transparent" }} ref={azureMediaPlayerRef}></video>
                <video src={'https://cdn.api.video/vod/' + videoId + '/mp4/source.mp4#t=0.001'} style={{ width: '400px', height: 'calc(100vh - 71px)', objectFit: 'contain',backgroundColor:"black" }} ref={azureMediaPlayerRef}></video> */}
                    {renderPlayPauseButton()}
                    {callToAction()}
                    {videoloading()}
                    {renderDialog()}
                </div>
            </>
        }
    }

    const PdfReadyView = () => {
        if (smallDevices) {
            return <>
                {loading == true && circularIndeterminate()}
                {loading == false && <div className="Example__container__document">
                    <center>
                        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page height="500" pageNumber={pageNumber}>
                                <div style={{ paddingTop: "10px" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <IconButton
                                            aria-label="close"
                                            onClick={changePageBack}
                                            sx={{
                                                color: mainColor
                                            }}
                                        >
                                            <KeyboardDoubleArrowLeftIcon />
                                        </IconButton>
                                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                            <p style={{ color: mainColor, margin: 0 }}>Page {pageNumber} of {numPages}</p>
                                        </Box>
                                        <IconButton
                                            aria-label="close"
                                            onClick={changePageNext}
                                            sx={{
                                                color: mainColor
                                            }}
                                        >
                                            <KeyboardDoubleArrowRightIcon />
                                        </IconButton>
                                    </Box>
                                </div>
                            </Page>
                        </Document>
                        <CustomButton
                            sx={{ borderRadius: 10 }}
                            variant={'contained'}
                            onClick={() => goToCamiCard(navigate, camitagid, password)}
                        >
                            VIEW CAMICODE
                        </CustomButton>
                    </center>

                </div>}
            </>
        } else {
            return <>
                {loading == true && circularIndeterminate()}
                {loading == false &&
                    <div className="Example__container__document">
                        <center>
                            <div style={{ paddingTop: "10px" }}>
                                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} >
                                    <div style={{ paddingTop: "10px" }} >
                                        <Page height="600" pageNumber={pageNumber}>
                                            <Box sx={{ display: "flex", justifyContent: "space-evenly" }} >
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={changePageBack}
                                                    sx={{
                                                        color: mainColor
                                                    }}
                                                >
                                                    <KeyboardDoubleArrowLeftIcon />
                                                </IconButton>
                                                <p style={{ color: mainColor }}>Page {pageNumber} of {numPages} </p>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={changePageNext}
                                                    sx={{
                                                        color: mainColor
                                                    }}
                                                >
                                                    <KeyboardDoubleArrowRightIcon />
                                                </IconButton>
                                            </Box>
                                        </Page>
                                    </div>
                                </Document>
                                <CustomButton sx={{ borderRadius: 10, marginBottom: "35px" }} variant={'contained'} onClick={() => goToCamiCard(navigate, camitagid, password)} >VIEW CAMICODE</CustomButton>
                            </div>
                        </center>
                    </div>}
            </>
        }
    }

    const imageReadyView = () => {
        const imgStyle = {
            width: smallDevices ? '100vw' : '500px',
            objectFit: 'contain',
            backgroundColor: 'transparent',
            height: smallDevices ? '60vh' : 'calc(100vh - 146px)',
            marginTop: smallDevices ? '65px' : '17px',
        };

        const boxStyle = {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: smallDevices ? '300px' : '0',
            marginTop: smallDevices ? '10px' : '15px',
        };

        return (
            <>
                {loading ? (
                    circularIndeterminate()
                ) : (
                    <div>
                        <div style={videoUpload}>
                            <img src={fileUrl} style={imgStyle} playsInline />
                        </div>
                        {callToImgAction()}
                       {/*  
                         <Box sx={boxStyle}>
                            <CustomButton
                                sx={{ borderRadius: 10, fontSize: '14px' }}
                                variant="contained"
                                onClick={() => goToCamiCard(navigate, camitagid, password)}>
                                VIEW CAMICODE
                            </CustomButton>
                        </Box>*/}
                    </div>
                )}
            </>
        );
    };

    const videoProcessingView = () => {
        return (
            <>
                <div className="content-section">
                    <div className="processing-content">
                        {/* We are processing the video. Please check back after few minutes.*/}
                    </div>
                </div>
                <div className="loadericon loadericon-watch" >
                    <Loader />
                    <div>We are processing the video. Please check back after few minutes.</div>
                </div>
            </>
        );
    }


    const clickcount = () => {
        if (locationData) {
            UpdatectacountLocation(locationData)
                .then((response) => {
                    // Handle response if needed
                })
                .catch((error) => {
                    console.error('Error updating count location:', error);
                    // Handle error as needed
                });
        } else {
            console.error('Location data not available');
            // Handle case where location data is not available
        }
        Updatectacount(camiId)
            .then((response) => {
            })
            .catch((error) => {
                // createLogDetails(logErrordata)
            });
    }

    const imageAvailabilityRouter = () => {
        if (videoAvailability === 'processing') {
            return <>{videoProcessingView()}</>
        } else if (videoAvailability === 'ready') {
            return <div sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <div style={(imageType == "pdf") ? {} : hide}>
                    {PdfReadyView()}
                </div>
                <div style={(imageType == "png" || imageType == "jpg" || imageType == "heif" || imageType == "gif" || imageType == "heic" || imageType == "jpeg") ? {} : hide}>
                    {imageReadyView()}
                </div>
                <div style={(imageType != "png" && imageType != "jpg" && imageType != "heif" && imageType != "gif" && imageType != "heic" && imageType != "jpeg") ? {} : hide} >
                    {videoReadyView()}
                </div>
            </div>
        }
        else {
            return null;
        }
    }


    if (videoId || fileUrl || foreverCami) {
        return (<div className="App" >{imageAvailabilityRouter()}</div>);
    } else if (showAlert) {
        return (
            <Box >
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60vh", maxWidth: "100%", }}>
                    <Alert onClose={handleCloseAlert} severity="info">
                        There is no video currently uploaded to this CAMIcode
                    </Alert>
                </Box>
                {loginLink == true && <Box sx={{ display: "flex", flexDirection: "column", margin:"10px",alignItems: "center", justifyContent: "center", maxWidth: "100%", }}>
                    <Link to="/login" href="https://camicode.com/pages/privacy-policy" underline="none" color="#981D97"
                        style={{
                            padding: 0,
                            borderColor: "#981D97",
                            color: "blue",
                            margin:"10px",
                            alignItems: "center"
                        }}
                    >
                        Registered CAMI user?Login here
                    </Link>
                   <Link to="/register" href="https://camicode.com/pages/privacy-policy" underline="none" color="#981D97"
                        style={{
                            borderColor: "#981D97",
                            padding: 0,
                            color: "blue",
                            marginLeft:"20px",
                            alignItems: "center",
                            justifyContent:"center"
                        }}
                    >
                        Not a registered CAMI user?Create an account
                    </Link>
                   
                </Box>
                }
            </Box>
        );
    }
    else {
        return <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
        }}> <CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>
    }
};
