import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';



import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';


import { validateResetForm } from '../../Business/AuthenticationBusiness';
import { useNavigate } from 'react-router-dom';

import { resetPassword } from '../../Business/AuthenticationBusiness';
import { useLocation } from "react-router-dom";
import { Alert } from "@mui/material";

function ResetPassword(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const xlPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 80,
    width: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
  };
  const smPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 2,
  };
  const errorText = {
    color: "red",
    fontSize: 13,
    marginTop: 1,
  };

  const mainColor = "#981D97";
  const whiteColor = "#000";
  const inputFieldColor = "#ecedee";
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(whiteColor),
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));
  const initialValues = {
    password: "",
    showPassword: "false",
    showConfirmPassword: "false",
    confirmPassword: ""
  };
  const passwordConditionsStyle = {
    fontSize: 12,
    paddingTop: 10,
    marginLeft: 5,
    marginRight: 5
  }
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [showSucess, setShowSuccess] = useState(false);

  const query = useLocation().search;
  const token = new URLSearchParams(query).get('oobCode').toString();
  const continueUrl = new URLSearchParams(query).get('continueUrl').toString();
  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = validateResetForm(formValues);
    setFormErrors(validation.errorList);
    if (validation.status === true) {
      setIsSubmit(true);
      resetPassword(token, formValues.password)
        .then((success) => {
          if (continueUrl == "https://camicode.com") {
            window.location.href = continueUrl;
          } else {
            setShowSuccess(true);
          }
          setIsSubmit(false);
        })
        .catch((error) => {
          setIsSubmit(false);
        });
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleClickShowPassword = () => {
    setFormValues({
      ...formValues,
      showPassword: !formValues.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setFormValues({
      ...formValues,
      showConfirmPassword: !formValues.showConfirmPassword,
    });
  };

  /**HTML Section */
  const renderResetPasswordForm = () => {
    if (matches) {
      return (
        <Box elevation={0} style={smPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: '100%',
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box  >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "left",
                    alignItems: "flex-start",
                    color: "#981D97",
                  }}
                >
                  Reset Password
                </Typography>
                {renderRSFormContent()}
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      );
    } else {
      return (
        <Paper elevation={2} style={xlPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: '100%',
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box sx={{ width: '100%' }} >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "flex",
                    alignItems: "flex-start",
                    color: "#981D97",
                  }}
                >
                  Reset Password
                </Typography>
                {renderRSFormContent()}
              </Box>
            </Container>
          </ThemeProvider>
        </Paper>
      );
    }
  };

  const renderRSFormContent = () => {
    if (isSubmit === true) {
      return (
        <Box sx={{ textAlign: "center" }} >
          <CircularProgress sx={{
            marginTop: 3,
            marginBottom: 3,
            marginLeft: "auto",
            marginRight: "auto"
          }} />
        </Box>
      );
    } else {
      return (<>{ResetFormContent()}</>);
    }
  }
  const renderAlert = () => {
    return (
      <Box sx={{ marginTop: "10vh" }}>
        <Alert severity="success" >
          Password Changed!
        </Alert> </Box>

    )
  }
  const ResetFormContent = () => {
    if (showSucess == true) {
      return (
        <>{renderAlert()}</>
      );

    } else return (
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <InputBase
          size="small"
          name="password"
          placeholder="Password"
          type={formValues.showPassword ? 'password' : 'text'}
          id="password"
          autoComplete="current-password"
          value={formValues.password}
          onChange={handleChange}
          sx={{
            width: "100%",
            backgroundColor: inputFieldColor,
            paddingBlock: 1,
            paddingInlineStart: 1,
            paddingInlineEnd: 1,
            borderRadius: 1,
            mt: 2,
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onMouseUp={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
                onTouchStart={handleClickShowPassword}
                onTouchEnd={handleClickShowPassword}
                onTouchCancel={handleClickShowPassword}
                edge="end"
              >
                {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>}
        />

        {formErrors.passwordValid && (<p style={errorText}>{formErrors.passwordValid}</p>)}

        {!formErrors.passwordValid && (<p style={passwordConditionsStyle}>Use 8 or more characters with a mix of letters, numbers & symbols</p>)}

        <InputBase
          size="small"
          name="confirmPassword"
          placeholder="Confirm Password"
          type={formValues.showConfirmPassword ? 'password' : 'text'}
          id="confirm_Password"
          // autoComplete="current-password"
          value={formValues.confirmPassword}
          onChange={handleChange}
          sx={{
            width: "100%",
            backgroundColor: inputFieldColor,
            paddingBlock: 1,
            paddingInlineStart: 1,
            paddingInlineEnd: 1,
            borderRadius: 1,
            mt: 2,
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onMouseUp={handleClickShowConfirmPassword}
                onMouseDown={handleClickShowConfirmPassword}
                onTouchStart={handleClickShowConfirmPassword}
                onTouchEnd={handleClickShowConfirmPassword}
                onTouchCancel={handleClickShowConfirmPassword}
                edge="end"
              >
                {formValues.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>}
        />


        {formErrors.password && (
          <p style={errorText}>{formErrors.password}</p>
        )}
        {apiError && <p style={errorText}>{apiError}</p>}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 3,
            marginBottom: 2,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ColorButton
            type="submit"
            sx={{
              borderRadius: 30,
              width: 230,
              margin: 3,
            }}
            variant="contained"
          >
            SUBMIT
          </ColorButton>
        </div>
      </Box>
    )
  };
  return <>{renderResetPasswordForm()}</>;
}

export default ResetPassword;