import { Box } from "@mui/material";
import MediaRecorder from "../Components/MediaRecorder";


const RecordVideo = () => {

    
    let RCStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        lineHeight: '0.6',
        width: {
            xs: '100%',
            sm: '100%',
            md: '600px',
        }
    };

    return (
    <Box 
        sx={{ 
            display: "flex",
            flexDirection: "column",
                alignItems: "center",
            }}>
        <Box sx={RCStyle} className="record-container" >
            <MediaRecorder />
        </Box>
      </Box>
    );
};

export default RecordVideo;