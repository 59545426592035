import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import google from "./../../assets/images/socialMedia/google.svg";
import apple from "./../../assets/images/socialMedia/apple.svg";
import facebook from "./../../assets/images/socialMedia/facebook.svg";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from "react";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { validateLoginForm, validateFacebookEmail, } from "../../Business/AuthenticationBusiness";
import { loginFirebaseAccountWithFacebook } from "../../Business/AuthenticationBusiness";
import { loginFirebaseAccountWithGoogle } from "../../Business/AuthenticationBusiness";
import { loginFirebaseWithPassword } from "../../Business/AuthenticationBusiness";
import { loginFirebaseAccountWithApple } from "../../Business/AuthenticationBusiness";
import { getUserByAuthId, getUserByEmail } from "../../Repository/Api";
import { getFirebaseAuthModule } from "../../Repository/Authentication/Firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";
import { CreatePasswordBasedAccount } from "../../Repository/Authentication/Firebase";
import { getFirebaseAuthJWTToken } from "../../Repository/Authentication/Firebase";
import { createUser, verifyEmailFirebase } from "./../../Repository/Api";
import { signOut } from '../../Business/AuthenticationBusiness';
import { createFirebaseAccountWithGoogle } from "../../Business/AuthenticationBusiness";
import { updateEmailId } from "../../Repository/Authentication/Firebase"
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login(props) {


  const [openAlert, setOpenAlert] = useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };


  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  }


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  let _location = useLocation();
  let _fromRoute = _location.state?.from?.pathname || "/cami-steps";

  const xlPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 80,
    width: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
    marginBottom: 60
  };
  const smPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 2,
    width: "100%"
  };

  const errorText = {
    color: "red",
    fontSize: 13,
    marginTop: 1,
    marginBottom: 1,
  };

  const socialLogo = {
    padding: 16,
    height: 64,

  }
  const socialLogoContainer = {
    my: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  };

  const mainColor = "#981D97";
  const whiteColor = "#000";
  const inputFieldColor = "#ecedee";

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(whiteColor),
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));

  const initialValues = {
    username: "",
    email: "",
    password: "",
    showPassword: "false",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEmailSubmit, setEmailSubmit] = useState(false);
  const [showUi, setShowUi] = useState(false);
  const [emailOfUser, setEmailOfUser] = useState("");
  const [authId, setAuthId] = useState("");
  const [signInMethod, setSignInMethod] = useState("");
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleClickShowPassword = () => {
    setFormValues({
      ...formValues,
      showPassword: !formValues.showPassword,
    });
  };

  useEffect(()=>{
    reDirectFromQueryUrl()
  },[]);

  function reDirectFromQueryUrl(){
    const auth =  getFirebaseAuthModule();
    auth.onAuthStateChanged((user)=>{
        if(user){
           navigate("/cami-steps")
        }else{
           navigate("/login")
        }
    })
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = validateLoginForm(formValues);
    setFormErrors(validation.errorList);
    if (validation.status === true) {
      setIsSubmit(true);
      loginFirebaseWithPassword(formValues)
        .then((response) => {
          setIsSubmit(false);
          setApiError("Login Successful");
          setFormValues(initialValues);
          console.log(props['type'], ":Props type")
          e.stopPropagation();
          if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
            navigate(_fromRoute, { replace: true });
          }
        })
        .catch((error) => {
          setIsSubmit(false);
          setApiError(error);
        });
    }
  };


  const handleGoogleLogin = (e) => {
    loginFirebaseAccountWithGoogle()
      .then((response) => {
        setIsSubmit(true)
        console.log(response, "handleGoogleLogin Response")
        const credential = GoogleAuthProvider.credentialFromResult(response);
        const token = credential.accessToken;
        const user = response.user;
        const auth = getFirebaseAuthModule();
        let email = response.user.providerData[0].email;
        let authId = response.user.uid
        console.log(authId, 'auth');
        getUserByAuthId(authId).then((response) => {
          console.log(response, "email exist or not")
          console.log(response.authId, 'authid create');
          setIsSubmit(false);
          setApiError("Login Successful.");
          if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
            navigate(_fromRoute, { replace: true });
          }
        }).catch((error) => {
          console.log(error, "No account in DB. So creating User")
          return new Promise((resolve, reject) => {
            getFirebaseAuthJWTToken(auth)
              .then((IDTOKEN) => {
                setIsSubmit(true);
                console.log(credential, token, user);
                localStorage.setItem("logintype", "google");
                localStorage.setItem("GOUC", user);
                localStorage.setItem("JWTTOKEN", IDTOKEN);
                const createUserData = {
                  authId: user.uid,
                  signInMethod: credential.signInMethod,
                  email: user.providerData[0].email,
                  firstName: user.displayName,
                  lastName: "",
                  userTypeId: 1,
                  userRoleId: 0,
                  createdBy: 0,
                  recordStatus: true,
                  registerStatus: true,

                };
                createUser(createUserData)
                  .then((response) => {
                    console.log(response, "respGoogle")
                    resolve(response);
                    setIsSubmit(false);
                    console.log("account created2")
                    if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
                      navigate(_fromRoute, { replace: true });
                    }
                  })
                  .catch((error1) => {
                    console.log(error1, "error1Google")
                    reject(error1);
                  });
                console.log("account created")
              })
              .catch((error3) => {
                console.log(error3, "error3Google")
                console.log("account created1")
                reject(error3);
              });
          })
        })
      }).catch((error) => {
        setApiError(error);
      });
  }

  const handleFacebookLogin = (e) => {
    loginFirebaseAccountWithFacebook()
      .then((response) => {
        setIsSubmit(true)
        console.log(response, "handleFacebookLogin Response")
        const credential = FacebookAuthProvider.credentialFromResult(response);
        const token = credential.accessToken;
        const user = response.user;
        const auth = getFirebaseAuthModule();
        let email = response.user.providerData[0].email;
        let authId = response.user.uid
        getUserByAuthId(authId).then((response) => {
          console.log(response, "email exist or not")
          setIsSubmit(false);
          setApiError("Login Successful.");
          if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
            navigate(_fromRoute, { replace: true });
          }
        }).catch((error) => {
          console.log(error, "No account in DB. So creating User")
          return new Promise((resolve, reject) => {
            getFirebaseAuthJWTToken(auth)
              .then((IDTOKEN) => {
                // verifyEmailFirebase(user.uid).then((res) => {
                //   console.log(res);
                // })
                setIsSubmit(true);
                console.log(credential, token, user);
                localStorage.setItem("logintype", "facebook");
                localStorage.setItem("FBUC", user);
                localStorage.setItem("JWTTOKEN", IDTOKEN);
                setAuthId(user.uid);
                setSignInMethod(credential.signInMethod);
                setEmailOfUser(user.email)
                setFirstName(user.displayName);
                if (user.email !== null) {
                  setShowUi(false)
                  console.log('inside email check')
                  const createUserData = {
                    authId: user.uid,
                    signInMethod: credential.signInMethod,
                    email: user.providerData[0].email,
                    firstName: user.displayName,
                    lastName: "",
                    userTypeId: 1,
                    userRoleId: 0,
                    createdBy: 0,
                    recordStatus: true,
                    registerStatus: true,

                  };
                  createUser(createUserData)
                    .then((response) => {
                      console.log(response, "respFaceBook")
                      resolve(response);
                      setIsSubmit(false)
                      console.log("account created2 FaceBook")
                      if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
                        navigate(_fromRoute, { replace: true });
                      }
                    })
                    .catch((error1) => {
                      console.log(error1, "error1FaceBook")
                      reject(error1);
                    });
                } else {
                  console.log('no email in this facebook account')
                  setShowUi(true)
                  console.log("update");

                }
              })
              .catch((error3) => {
                console.log(error3, "error3FaceBook")
                console.log("account created1 FaceBook")
                reject(error3);
              });
          })
        })
      }).catch((error) => {
        setApiError(error);
      })

  }
  const handleSubmitEmail = (e) => {
    setIsSubmit(true)
    if (emailOfUser) {
      const createUserData = {
        "authId": authId,
        "signInMethod": signInMethod,
        "email": emailOfUser,
        "firstName": firstName,
        "lastName": "",
        "userTypeId": 1,
        "userRoleId": 0,
        "createdBy": 0,
        "recordStatus": true,
        "registerStatus": true,
      };
      const validation = validateFacebookEmail(createUserData);
      if (validation.status === true) {
        console.log('inside createuser')
        createUser(createUserData)
          .then((response) => {
            console.log(' created user')
            updateEmailId(emailOfUser);
            console.log(response, 'created User in DB');
            setIsSubmit(false)
            // setEmailSubmit(true);
            setApiError("Login Successful");
            console.log('logged In')

          }).then(() => {
            if (typeof props['type'] == 'undefined' && props['type'] !== 'imported') {
              console.log('Inside Prop type')
              navigate(_fromRoute, { replace: true });
              console.log('Naviagted to Home')
            }
          })
          .catch((error) => {
            // setEmailSubmit(false);
            setApiError(error);
            console.log(error, 'err');
          });

      }

    }

  }




  const handleAppleLogin = (e) => {
    loginFirebaseAccountWithApple()
      .then((response) => {
        setIsSubmit(true);
        console.log(response, "handleAppleLogin Response")
        const credential = OAuthProvider.credentialFromResult(response);
        const token = credential.accessToken;
        const user = response.user;
        const auth = getFirebaseAuthModule();
        let email = response.user.providerData[0].email;
        let authId = response.user.uid
        getUserByAuthId(authId).then((response) => {
          localStorage.setItem('email', email);
          console.log(response, "email exist or not")
          setIsSubmit(false);
          setApiError("Login Successful.");
          if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
            navigate(_fromRoute, { replace: true });
          }
        }).catch((error) => {
          console.log(error, "No account in DB. So creating User")
          return new Promise((resolve, reject) => {
            getFirebaseAuthJWTToken(auth)
              .then((IDTOKEN) => {
                setIsSubmit(true);
                console.log(credential, token, user);
                localStorage.setItem("logintype", "apple");
                localStorage.setItem("APUC", user);
                localStorage.setItem("JWTTOKEN", IDTOKEN);
                const createUserData = {
                  authId: user.uid,
                  signInMethod: credential.signInMethod,
                  email: user.providerData[0].email,
                  firstName: user.providerData[0].email.substring(0, user.providerData[0].email.lastIndexOf("@")),
                  lastName: "",
                  userTypeId: 1,
                  userRoleId: 0,
                  createdBy: 0,
                  recordStatus: true,
                  registerStatus: true,
                };
                createUser(createUserData)
                  .then((response) => {
                    console.log(response, "respApple")
                    setIsSubmit(false);
                    resolve(response);
                    console.log("account created2 Apple")
                    if (typeof props['type'] == 'undefined' && props['type'] != 'imported') {
                      navigate(_fromRoute, { replace: true });
                    }
                  })
                  .catch((error1) => {
                    console.log(error1, "error1Apple")
                    reject(error1);
                  });
                console.log("account created")
              })
              .catch((error3) => {
                console.log(error3, "ErrorLoginApple")
                if (error3.code === "auth/user-cancelled") {
                  reject("User cancelled Login");
                  setApiError("User cancelled Login");
                }
                if (error3.code === "auth/cancelled-popup-request") {
                  console.log(error3.message);
                  reject("User cancelled popup request")
                  setApiError("User cancelled popup request");
                }
                if (error3.code === "auth/popup-closed-by-user") {
                  console.log(error3.message);
                  reject("Popup Closed by User")
                  setApiError("Popup Closed by User");
                }

              });
          })
        })
      }).catch((error) => {
        setApiError(error);
      })
  }

  /**HTML Section */
  const renderLoginForm = () => {
    if (matches) {
      return (
        <Box elevation={0} style={smPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: '100%',
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box  >

                {showUi == false && renderLoginFormContent()}
                {showUi == true && renderEmailFromUser()}
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      );
    } else {
      return (
        <Paper elevation={2} style={xlPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: '100%',
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box>

                {showUi == false && renderLoginFormContent()}
                {showUi == true && renderEmailFromContent()}

              </Box>

            </Container>
          </ThemeProvider>
        </Paper>
      );
    }
  };


  const renderEmailFromContent = () => {
    if (isSubmit === true) {
      return (
        <Box sx={{ textAlign: "center" }} >
          <CircularProgress sx={{
            marginTop: 3,
            marginBottom: 3,
            marginLeft: "auto",
            marginRight: "auto"
          }} />
        </Box>
      );
    } else {
      return (<>{renderEmailFromUser()}</>);
    }
  }


  const renderEmailFromUser = () => {
    // if (showUi == true) {
    return (
      <>
        <Typography
          variant="h5"
          sx={{
            textAlign: "left",
            alignItems: "flex-start",
            color: "#981d97", mb: 2
          }}
        >
          Please enter your email ID to Create account!
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            mb: 2,
          }}
        >
          <InputBase
            size="small"
            id="user email"
            placeholder="Email"
            name="user email"
            autoComplete="email"
            autoFocus
            value={emailOfUser}
            onChange={(e) => { setEmailOfUser(e.target.value) }}
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
            }}
          />
          <ColorButton
            onClick={handleSubmitEmail}
            sx={{
              borderRadius: 30,
              width: 140
            }}
            variant="contained"
          >
            CONTINUE
          </ColorButton>
        </Box>
      </>
    )

    // }
  }


  const renderLoginFormContent = () => {
    if (isSubmit === true) {
      return (
        <Box>
          <Box sx={{ textAlign: "center" }} >
            <CircularProgress sx={{
              marginTop: 3,
              marginBottom: 3,
              marginLeft: "auto",
              marginRight: "auto"
            }} />
          </Box>
        </Box>
      );
    } else {
      return (<>{loginFormContent()}</>);
    }
  }

  const loginFormContent = () => {
    return (
      <>   <Typography
        variant="h5"
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "flex",
          color: "#981D97",
        }}
      >
        Log In
      </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <InputBase
            size="small"
            id="email"
            placeholder="Email"
            name="email"
            autoFocus = {true}
            autoComplete="email"
            value={formValues.email}
            onChange={handleChange}
            sx={{
              width: '100%',
              mt: 2,
              mb: 2,
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
            }}
          />
          {formErrors.email && (<p style={errorText}>{formErrors.email}</p>)}
          {formErrors.emailValid && (<p style={errorText}>{formErrors.emailValid}</p>)}
          <InputBase
            size="small"
            name="password"
            placeholder="Password"
            type={formValues.showPassword ? 'password' : 'text'}
            id="password"
            autoComplete="current-password"
            value={formValues.password}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseUp={handleClickShowPassword}
                  onMouseDown={handleClickShowPassword}
                  onTouchStart={handleClickShowPassword}
                  onTouchEnd={handleClickShowPassword}
                  onTouchCancel={handleClickShowPassword}
                  edge="end"
                >
                  {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            sx={{
              width: '100%',
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
              mb: 1,
            }}
          />
          {formErrors.password && <p style={errorText}>{formErrors.password}</p>}
          {formErrors.passwordValid && <p style={errorText}>{formErrors.passwordValid}</p>}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 3,
              marginBottom: 2,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ColorButton
              type="submit"
              sx={{
                borderRadius: 30,
                width: 140,
              }}
              variant="contained"
            >
              LOGIN
            </ColorButton>
            <Grid container sx={socialLogoContainer}>
              <Link href="#"> <img src={google} style={socialLogo} onClick={handleGoogleLogin}></img> </Link>
              <Link href="#"> <img src={apple} style={socialLogo} onClick={handleAppleLogin}></img> </Link>
              <Link href="#"> <img src={facebook} style={socialLogo} onClick={handleFacebookLogin}></img> </Link>
            </Grid>
            {
              (typeof props['type'] == 'undefined' && props['type'] != 'imported') &&
              <>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }} >
                  <Grid
                    item
                    sx={{
                      color: "black",
                    }} >
                    <Link
                      href="/register"
                      variant="body2"
                      color="inherit"
                      sx={{ color: "#981D97" }}>
                      {"Sign Up Today"}
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }} >
                  <Grid
                    item
                    sx={{
                      color: "black",
                      mt: 1
                    }} >
                    <Link
                      href="/forgot-password"
                      variant="body2"
                      color="inherit"
                      sx={{ color: "#981D97", }} >
                      Forgot Password?
                    </Link>
                  </Grid>
                </Grid>
              </>
            }

            {apiError && (<p style={errorText}>{apiError}</p>)}
          </div>
        </Box>

        {openAlert && (<Stack spacing={2} sx={{ width: '100%' }}>

          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
              User account doesn't exist. Please signUp.
            </Alert>
          </Snackbar>

        </Stack>)}

      </>
    );
  };

  return <>{renderLoginForm()}</>;
}
export default Login;
