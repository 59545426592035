import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup, OAuthProvider, setPersistence, browserLocalPersistence, confirmPasswordReset
} from "firebase/auth";
import React, { useEffect } from "react";
import { createUser, verifyEmailFirebase } from "../Repository/Api";
import {
  getFirebaseAuthModule,
  CreatePasswordBasedAccount,
  getFirebaseAuthJWTToken,
  getGoogleProvider,
  getFacebookProvider,
  signinWithApple,
  loginPasswordBasedAccount,
  doSignOut,
  sendFirebasePasswordResetEmail,
  getFacebookEmail,

} from "../Repository/Authentication/Firebase";
import { Navigate, useLocation } from 'react-router-dom';
import { getCurrentSignedInUser } from "../Repository/Authentication/Firebase";
import { Outlet } from "react-router-dom";
import { take } from "rxjs";


export let createFirebaseAccountWithPassword = (formValues) => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const email = formValues.email;
    const password = formValues.password;
    CreatePasswordBasedAccount(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem("logintype", "password");
        localStorage.setItem("PSUC", JSON.stringify(userCredential));
            const createUserData = {
              authId: userCredential.user.uid,
              signInMethod: "password",
              email: email,
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              company: formValues.company,
              userTypeId: 1,
              userRoleId: 0,
              createdBy: 0,
              recordStatus: true,
              registerStatus: true,
            };
            createUser(createUserData)
              .then((response) => {
                getFirebaseAuthJWTToken(auth)
          .then((IDTOKEN) => {
            localStorage.setItem("JWTTOKEN", IDTOKEN)
                resolve(response);
              })
              .catch((error1) => {
                reject(error1);
              });
          })
          .catch((error2) => {
            reject(error2);
          });
  });
});
}

export let createFirebaseAccountWithGoogle = () => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const provider = getGoogleProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        
            const createUserData = {
              authId: user.uid,
              signInMethod: credential.signInMethod,
              email: user.providerData[0].email,
              firstName: user.displayName,
              lastName: user.last_name,
              company: user.company,
              userTypeId: 1,
              userRoleId: 0,
              createdBy: 0,
              recordStatus: true,
              registerStatus: true,
            };
            createUser(createUserData)
              .then((response) => {
                getFirebaseAuthJWTToken(auth)
          .then((IDTOKEN) => {
           
            localStorage.setItem("logintype", "google");
            localStorage.setItem("GOUC", user);
            localStorage.setItem("JWTTOKEN", IDTOKEN);
                resolve(response);
              })
              .catch((error1) => {
                reject(error1);
              });
      }).catch((error1) => {
        reject(error1);
      })
      .catch((error) => {
        if (error.code === "auth/popup-closed-by-user") {
          reject("Popup Closed by User");
        } if (error.code === "auth/user-cancelled") {
          reject("User cancelled Login");
        }
        if (error.code === "auth/cancelled-popup-request") {
       
          reject("User cancelled popup request")
        }

      });
  });
})};

export let createFirebaseAccountWithFacebook = () => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const provider = getFacebookProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        getFirebaseAuthJWTToken(auth)
          .then((IDTOKEN) => {
            verifyEmailFirebase(user.uid).then((res) => {
              console.log(res);
            })
            localStorage.setItem("logintype", "facebook");
            localStorage.setItem("FBUC", user);
            localStorage.setItem("JWTTOKEN", IDTOKEN);
            resolve(result)
          })
          .catch((error3) => {
           
            reject(error3);
          });
      })
      .catch((error) => {
       
        if (error.code === "auth/user-cancelled") {
          reject("User cancelled Login");
        }
        if (error.code === "auth/cancelled-popup-request") {
         
          reject("User cancelled popup request")
        }
        if (error.code === "auth/popup-closed-by-user") {
         
          reject("Popup Closed by User")
        }
        if (error.code === 'auth/account-exists-with-different-credential') {
        
          reject("user email already exit")
        }


      });
  });
};


export let createFirebaseAccountWithApple = () => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const provider = signinWithApple();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        // if (user.email !== '') {
        //   console.log('if inside');
        //   signinWithApple(token)
        //     .then((res) => {
        //       console.log(res, "responseapplelogin", auth);
        //       if (res.email) {
        //         user.email = res.email;
        //       }
        
            const createUserData = {
              authId: user.uid,
              signInMethod: credential.signInMethod,
              email: user.providerData[0].email,
              firstName: user.providerData[0].email.substring(0, user.providerData[0].email.lastIndexOf("@")),
              lastName: "",
              company: "",
              userTypeId: 1,
              userRoleId: 0,
              createdBy: 0,
              recordStatus: true,
              registerStatus: true,

            };
           
            createUser(createUserData)
              .then((response) => {
                getFirebaseAuthJWTToken(auth)
          .then((IDTOKEN) => {
           
            localStorage.setItem("logintype", "apple");
            localStorage.setItem("APUC", user);
            localStorage.setItem("JWTTOKEN", IDTOKEN);
                
                resolve(response);
              })
              .catch((error1) => {
               
                reject(error1);
              });
          // })
          // .catch((error3) => {
          //   console.log(error3, "error3Apple")
          //   reject(error3);
          // });
      })
      .catch((err) => {
      
        reject(err);
      });
    //   }
    // })
    // .catch((error) => {
    //   console.log(error, "errorApple");
    //   if (error.code === "auth/popup-closed-by-user") {
    //     reject("Popup Closed by User");
    //   }

    // });
  });
})};

export let loginFirebaseWithPassword = (formValues) => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const email = formValues.email;
    const password = formValues.password;

    loginPasswordBasedAccount(auth, email, password)
      .then((UserCredential) => {
        
        getFirebaseAuthJWTToken(auth)
          .then((IDTOKEN) => {
            localStorage.setItem("JWTTOKEN", IDTOKEN);
            resolve(UserCredential);

          })
          .catch((error2) => {
            if (error2.code === "auth/user-not-found") {
              reject("User not found");
            }
          });
      })
      .catch((error1) => {

        reject(error1);

      });
  });
};

export let loginFirebaseAccountWithGoogle = () => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const provider = getGoogleProvider();
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;
          getFirebaseAuthJWTToken(auth)
            .then((IDTOKEN) => {
              localStorage.setItem("logintype", "google");
              localStorage.setItem("GOUC", user);
              localStorage.setItem("JWTTOKEN", IDTOKEN);
              resolve(result);
            })
            .catch((error3) => {
             
              reject(error3);
            });
        })
        .catch((error) => {
          if (error.code === "auth/user-cancelled") {
            reject("User cancelled Login");
          }
          if (error.code === "auth/cancelled-popup-request") {
            
            reject("User cancelled popup request")
          }
          if (error.code === "auth/popup-closed-by-user") {
            
            reject("Popup Closed by User")
          }

        });
    });
  });
};

export let loginFirebaseAccountWithFacebook = () => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const provider = getFacebookProvider();
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;
          getFirebaseAuthJWTToken(auth)
            .then((IDTOKEN) => {
              verifyEmailFirebase(user.uid).then((res) => {
                console.log(res);
              })
              localStorage.setItem("logintype", "facebook");
              localStorage.setItem("FBUC", user);
              localStorage.setItem("JWTTOKEN", IDTOKEN);
              resolve(result);
            })
            .catch((error3) => {
              reject(error3);
            });
        })
        .catch((error) => {
          if (error.code === "auth/user-cancelled") {
            reject("User cancelled Login");
          }
          if (error.code === "auth/cancelled-popup-request") {
           
            reject("User cancelled popup request")
          }
          if (error.code === "auth/popup-closed-by-user") {
           
            reject("Popup Closed by User")
          }
          if (error.code === 'auth/account-exists-with-different-credential') {
           
            reject("user email already exit")
          }
        });
    });
  });
};

export let loginFirebaseAccountWithApple = () => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    const provider = signinWithApple();
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = OAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;
          getFirebaseAuthJWTToken(auth)
            .then((IDTOKEN) => {
              localStorage.setItem("logintype", "apple");
              localStorage.setItem("APUC", user);
              localStorage.setItem("JWTTOKEN", IDTOKEN);
              resolve(result);
            })
            .catch((error3) => {
             
              reject(error3);
            });
        })
        .catch((error) => {
         
          if (error.code === "auth/user-cancelled") {
            reject("User cancelled Login");
          }
          if (error.code === "auth/cancelled-popup-request") {
           
            reject("User cancelled popup request")
          }
          if (error.code === "auth/popup-closed-by-user") {
           
            reject("Popup Closed by User")
          }
        });
    });
  });
};

export let signOut = () => {
  const auth = getFirebaseAuthModule();
  localStorage.clear();
  return doSignOut(auth);
}

export let validateSignupForm = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Please enter your first name.";
  }else{
   if(isTextFieldEmpty(values.firstName)) {
      errors.firstName ="Please enter valid first name";
    }
  }

  if (!values.lastName) {
    errors.lastName = "Please enter your last name.";
  }else{
    if(!validateName(values.lastName)) {
      errors.lastName ="Please enter valid last name";
    }
  }
 
  if (!values.email) {
    errors.email = "Please enter your Email.";
  }
  else {
    if (!validateEmail(values.email)) {
      errors.emailValid = "Please enter valid Email.";
    }
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (!validatePassword(values.password)) {
    errors.passwordValid = " Use 8 or more characters with minimum 1 uppercase, 1 symbol and 1 numeric character";
  } if (typeof values.password !== "undefined" && typeof values.confirmPassword !== "undefined") {
    if (values.password != values.confirmPassword) {
      errors.password = "Passwords don't match.";
    }
  }
  return {
    status: Object.keys(errors).length > 0 ? false : true,
    errorList: errors,
  };
};

export let validateLoginForm = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter your Email.";
  } else {
    if (!validateEmail(values.email)) {
      errors.emailValid = "Please enter valid Email.";
    }
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (!validatePassword(values.password)) {
    errors.passwordValid = "Passowrd must have minimum 8 characters, 1 uppercase, 1 symbol and 1 numeric character";
  }

  return {
    status: Object.keys(errors).length > 0 ? false : true,
    errorList: errors,
  };
};

export let validateforeverform=(values)=>{
  const errors={};
  if(!values.camicode){
    errors.code = "please enter your camicode"
  }else{
    if(values.camicode.length <0){
      errors.codeValid = "please enter valid camicode"
    }
  }
  if (!values.email) {
    errors.email = "Please enter your Email.";
  } else {
    if (!validateEmail(values.email)) {
      errors.emailValid = "Please enter valid Email.";
    }
  }
  return {
    status:Object.keys(errors).length>0?false:true,
    errorList :errors
  }

}

export let validateProfileForm = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "First name cannot be empty";
   } else if (!validateName(values.firstName)) {
     errors.firstName = "Please enter a valid name."
   }

  if (!values.lastName) {
    errors.lastName = "Last name cannot be empty";
  } else if (!validateName(values.lastName)) {
    errors.lastName = "Please enter a valid name."
   }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter your PhoneNumer.";
  }
  // else if (!validatePhoneNumber(values.phoneNumber)) {
  //   errors.phoneNumber = "PhoneNumber  cannot be empty"
  // }
  return {
    status: Object.keys(errors).length > 0 ? false : true,
    errorList: errors,
  };
};
export let validateFacebookEmail = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter your Email.";
  } else {
    if (!validateEmail(values.email)) {
      errors.emailValid = "Please enter valid Email.";
    }
  }
  return {
    status: Object.keys(errors).length > 0 ? false : true,
    errorList: errors,
  };
};

export let validateResetForm = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Password is required";
  } else if (!validatePassword(values.password)) {
    errors.passwordValid = " Use 8 or more characters with minimum 1 uppercase, 1 symbol and 1 numeric character";
  } if (typeof values.password !== "undefined" && typeof values.confirmPassword !== "undefined") {
    if (values.password != values.confirmPassword) {
      errors.password = "Passwords don't match.";
    }
  }
  return {
    status: Object.keys(errors).length > 0 ? false : true,
    errorList: errors,
  };
};

export let validateName = (nameString) => {
  const regex = /^[A-Za-z0-9_ ]*$/i
  if (!regex.test(nameString)) {
    return false;
  } else {
    return true;
  }
}

export let validateEmail = (emailString) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!regex.test(emailString)) {
    return false;
  } else {
    return true;
  }
};

export  const isTextFieldEmpty = (firstname) => {
  return /^\s*$/.test(firstname);
}

export let validatePassword = (passwordString) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
  if (!regex.test(passwordString)) {
    return false;
  } else {
    return true;
  }
}
export let validatePhoneNumber = (phoneString) => {
  var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/i;

  if (!regex.test(phoneString)) {
    return false;
  } else {
    return true;
  }
}
export let validateNumber = (number) => {
  var regex = /^[0-9\b]+$/
  if (!regex.test(number)) {
    return false;
  } else {
    return true;
  }
}
export let RequireAuth = ({ children }) => {
  let auth = getFirebaseAuthModule();
  let webURL = useLocation();
  const token = localStorage.getItem('JWTTOKEN');
  if (!token || token == '') {
    const URL = webURL.pathname;
    const parse = new URLSearchParams(webURL.search);
    const theCamiId = parse.get("c")
    localStorage.setItem('theCamiId', theCamiId);
    return <Navigate to="/login" state={{ from: webURL }} replace />
  }
  return children;
}

export let validateForgetPasswordForm = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter your Email.";
  } else {
    if (!validateEmail(values.email)) {
      errors.emailValid = "Please enter valid Email.";
    }
  }
  return {
    status: Object.keys(errors).length > 0 ? false : true,
    errorList: errors,
  };
};

export let requestPasswordReset = (formValues) => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    sendFirebasePasswordResetEmail(auth, formValues.email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export let resetPassword = (code, password) => {
  return new Promise((resolve, reject) => {
    const auth = getFirebaseAuthModule();
    confirmPasswordReset(auth, code, password).then((response) => {
      // Success
     
      resolve(response);
    })
      .catch((error) => {
        reject(error);
      })
  })
}
const ModulesExported = {
  createFirebaseAccountWithPassword,
  createFirebaseAccountWithGoogle,
  createFirebaseAccountWithFacebook,
  validateSignupForm,
  validateEmail,
  loginFirebaseWithPassword,
  loginFirebaseAccountWithGoogle,
  loginFirebaseAccountWithFacebook,
  signOut,
  requestPasswordReset,
  validateFacebookEmail,
  resetPassword
};
export default ModulesExported;
