import React from 'react';
import { Routes, Route, Link, resolvePath } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../Pages/MyPayment.css';
import { getUserByAuthId, updateCardDetails } from '../../Repository/Api';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { createPaymentMethod, getCardDetails, deleteCard } from "../../Repository/Api";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { color } from '@mui/system';
import { CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { validateName, validateNumber } from '../../Business/AuthenticationBusiness';



export default function MyPaymentMethods() {

  const [number, SetNumber] = useState("");
  const [name, SetName] = useState("");
  const [month, SetMonth] = useState("");
  const [expiry, SetExpiry] = useState("");
  const [cvc, SetCvc] = useState("");
  const [focus, SetFocus] = useState("");
  const [cardDisplay, setCardDisplay] = useState(false)
  const [formDisplay, setFormDisplay] = useState(false)
  const [currentEmail, setCurrentEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [id, setId] = useState("");
  const [cardCreatedId, setCardCreatedId] = useState("");
  const [data, setData] = useState("");
  const [exp, SetExp] = useState("");
  const [cardData, setCardData] = useState("");
  const [pmId, setPmId] = useState("");
  const [brand, setBrand] = useState("");
  const [expMonth, SetExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [updateForm, setUpdateForm] = useState(false);
  const [updateMonth, setUpdateMonth] = useState(false);
  const [updateExpiry, SetUpdateExpiry] = useState(false);
  const [updateDone, SetUpdateDone] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [save, setSave] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardNameError, setCardNameError] = useState("");
  const [cvcNumberError, setCvcNumberError] = useState("");
  const [monthError, setMonthError] = useState("");
  const [yearError, setYearError] = useState("");
  const [networkRes, setNetworkRes] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const mainColor = "#981D97";

  const today = new Date();
  const CustomButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#000"),
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));

  const errorText = {
    color: "red",
    fontSize: 13,
    marginTop: 1,
    marginBottom: 1,
  };
  const errorTextNetwork = {
    color: "red",
    fontSize: 13,
    marginTop: 1,
    marginBottom: 5,
    textAlign: "center"
  };

  const dialogButtons = {
    display: 'block',
    margin: '10px auto',
    width: '30%',
    borderRadius: 20
  }



  const handleDate = (e) => {
    setNetworkRes('')
    if (updateForm == true) {
      setUpdateMonth(e.target.value);
    }
    SetMonth(e.target.value);
    console.log(e.target.value, 'expiry date');
  };
  const handleExpiry = (e) => {
    const value = (e.target.value);
    console.log(value);
    SetExpiry(value);
    setNetworkRes('')

  };

  useEffect(() => {
    if (month && expiry) {
      var someday = new Date();
      var today_mm = today.getMonth() + 1;
      var today_yy = today.getFullYear() % 100;
      // console.log(today_mm, today_yy, 'mm', 'yy')
      if (today_mm < 10) {
        today_mm = '0' + today_mm;
      }
      // console.log(today_mm, today_yy, 'mm', 'yy')
      var mm = month;
      var yr = expiry.toString();
      // console.log(yr);
      var yy = yr.substring(2, 4);
      // console.log(mm, yy, 'month', 'year');
      // console.log(someday)
      if (yy > today_yy || (yy == today_yy && mm >= today_mm)) {
        setYearError('');
      } else {
        console.log("The expiry date is before today's date. Please select a valid expiry date");
        setYearError("Please enter a valid month")
      }
    }
  }, [month])

  useEffect(() => {
    setCvcNumberError("")
  }, [cvc])

  useEffect(() => {
    if (expiry) {
      const someday = new Date();
      const currentDate = someday.getFullYear();

      if (currentDate > expiry) {
        // alert("The expiry date is before today's date. Please select a valid expiry date");
        setYearError("Please enter a valid year")
        setDisabled(true)
      } else {
        setYearError('');
        setDisabled(false);
      }
    }
  }, [expiry])

  useEffect(() => {
    getDetails();
  }, [currentEmail, id, firstName])

  useEffect(() => {
    const auth = getFirebaseAuthModule();
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((newToken) => {
          localStorage.setItem("JWTTOKEN", newToken);
          console.log(user);
          getUserEmailFromUid(user.uid);
          // console.log(user.uid);
          // setCurrentEmail(user.email);
        });
      } else if (user = undefined || null) {
        return;
      }
    }
    );
    // SetUpdateDone(false)
  }, [])

  const getUserEmailFromUid = async (authId) => {

    const response = await getUserByAuthId(authId)
      .then(response => {
        // console.log(response.id, response.email, 'res');
        setId(response.id);
        setCurrentEmail(response.email);
        setFirstName(response.firstName);

      }).catch(error => console.log(error, "error receiving data by email"))
  }
  useEffect(() => {
    if (pmId && expMonth && expYear && cardNumber) {
      setCardDisplay(true);
    } else {
      // setCardDisplay(true)
      setSave(false);
      setFormDisplay(false);
    }
  }, [cardData, pmId, expMonth, expYear, cardNumber])

  const getDetails = (e) => {
    // console.log(id, firstName, currentEmail);
    setCardDisplay(false);
    if (id && firstName && currentEmail) {
      // console.log(id, firstName, currentEmail)
      const _getCardDetail = {
        "id": id,
        "name": firstName,
        "email": currentEmail
      };

      getCardDetails(_getCardDetail)
        .then((response) => {
          console.log(response, response.id, response.brand, response.expMonth, response.expYear, response.number);
          setCardDisplay(true);
          console.log("card data obtained");
          setCardData(response);
          setPmId(response.id);
          setBrand(response.brand);
          SetExpMonth(response.expMonth);
          setExpYear(response.expYear);
          setCardNumber(response.number);
          setIsLoading(false);
          // SetUpdateDone(false)
        }).catch((error) => {
          setCardDisplay(false);
          console.log('no card. add a card');
          console.log((error));
          setIsLoading(false);
        })
    }
  }



  const handleCreateCardDetail = (e) => {
    e.preventDefault();
    const _cardNumber = validateNumber(number);
    const _name = validateName(name);
    const _cvcNumber = validateNumber(cvc);
    const _month = validateNumber(month);
    const _year = validateNumber(expiry);
    setSave(false);
    if (saveButton == true) {
      console.log('validation...');

      if (!_cardNumber) {
        setCardNumberError("Please enter a valid Number")
      } else {
        setCardNumberError("")
      }
      if (!_name) {
        setCardNameError("Please enter a valid Name")
      } else {
        setCardNameError("")
      }
      if (!_cvcNumber) {
        setCvcNumberError("Please enter a valid Number")
      } else {
        setCvcNumberError("")
      }
      if (!_month) {
        setMonthError("Please enter a valid Number")
      } else {
        setMonthError("")
      }
      if (!_year) {
        setYearError("Please enter a valid Number")
      } else {
        setYearError("")
      }
    } else if (saveButton == false) {
      // alert('validation');
      // inside
      if (!_cardNumber) {
        setCardNumberError("Please enter a valid Number")
      } else {
        setCardNumberError("")
      }
      if (!_name) {
        setCardNameError("Please enter a valid name")
      } else {
        setCardNameError("")
      }
      if (!_cvcNumber) {
        setCvcNumberError("Please enter a valid cvc Number")
      } else {
        setCvcNumberError("")
      }
      if (!_month) {
        setMonthError("Please enter a valid month")
      } else {
        setMonthError("")
      }
      if (!_year) {
        setYearError("Please enter a valid year")
      } else {
        setYearError("")
      }
      // if (_month && _year) {
      //   const someday = new Date();
      //   someday.setFullYear(_year, _month, 1);
      //   console.log(someday)
      //   if (someday < today) {
      //     alert("The expiry date is before today's date. Please select a valid expiry date");
      //     setYearError("Please enter a valid month W")
      //   } else {
      //     setYearError('');
      //   }
      // }
      if (_month && _year) {
        var someday = new Date();
        var today_mm = today.getMonth() + 1;
        var today_yy = today.getFullYear() % 100;
        // console.log(today_mm, today_yy, 'mm', 'yy')
        if (today_mm < 10) {
          today_mm = '0' + today_mm;
        }
        // console.log(today_mm, today_yy, 'mm', 'yy')
        var mm = month;
        var yr = expiry.toString();
        // console.log(yr);
        var yy = yr.substring(2, 4);
        // console.log(mm, yy, 'month', 'year');
        // console.log(someday)
        if (yy > today_yy || (yy == today_yy && mm >= today_mm)) {
          setYearError('');
        } else {
          console.log("The expiry date is before today's date. Please select a valid expiry date");
          setYearError("Please enter a valid month")
        }
      }

      console.log("inside");
      console.log(number, month, expiry, cvc, currentEmail, name);
      if (number && month && expiry && cvc && currentEmail && name) {
        const _cardDetail = {
          "number": number,
          "expMonth": parseInt(month),
          "expYear": parseInt(expiry),
          "cvc": cvc,
          "brand": "string",
          "id": "string",
          "email": currentEmail,
          "name": name,
          "status": ""
        };
        console.log(_cardDetail)
        createPaymentMethod(_cardDetail)
          .then((response) => {
            console.log(response, "res");
            if (!response.id) {
              console.log(response.status, "res");
              setNetworkRes(response.status);
            } else {
              console.log(response.id);
              setData(response);
              setCardDisplay(true);
              setFormDisplay(false);
              setCardCreatedId(response.id);
              setSave(false);
              setIsLoading(true);
              getDetails();
            }
          }).catch((error) => {
            console.log(error, "creteCard Error");
          })
      } else {
        console.log("error");
      }
    }
  }
  const handleDeleteCard = (e) => {

    setConfirm(true);

  }

  const confirmDete = () => {
    setIsLoading(true);
    setSave(false);
    console.log("inside delete card fn")
    console.log(pmId, "pmid ");
    if (pmId) {
      deleteCard(pmId).then((response) => {
        console.log(response, " card deleted");
        setFormDisplay(false);
        setCardDisplay(false);
        setSave(false);
        setIsLoading(false);


      }).catch((error) => {
        console.log(error, ' error deleting card');
      })

    }
    setSave(true)
  }

  const handleAddCard = (e) => {
    setFormDisplay(true)
    setCardDisplay(false)
    setSave(false)
  }

  const confirmDialog = () => {
    return (
      <Dialog open={confirm}>
        <Box>
          <DialogTitle sx={{ color: "black", fontSize: '1rem', marginTop:"10px" }}>Are you sure you want to delete?</DialogTitle>
          <DialogActions  sx={{ width: "100%" }}>
            <CustomButton sx={dialogButtons} variant={'contained'} onClick={() => { setConfirm(false) }} >No</CustomButton>
            <CustomButton sx={dialogButtons} variant={'contained'} onClick={() => { setConfirm(false); confirmDete(); }} >Yes</CustomButton>
          </DialogActions>
        </Box>

      </Dialog>
    )
  }

  const UserCard = () => {
    if (isLoading) {
      return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>

    } else {
      return (
        <>
          <h4 style={{ color: mainColor, display: "flex", textAlign: "center", justifyContent: "center", marginTop: "60px" }}> Your Card Details </h4>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              testAlign: "center",
              justifyContent: "center",
              color: mainColor,
              '& > :not(style)': {
                m: 1,
                width: 300,
                height: 200,
              },
            }}
          >
            <Paper style={{ backgroundImage: "linear-gradient(#981D97 ,#BF9BDE)" }} >
              <Box sx={{ display: "flex", justifyContent: "space-evenly", marginTop: 3 }}>
                <Typography sx={{ fontSize: 20, color: "#FFFFFF", fontWeight: "bold", fontFamily: "cursive" }} >
                  CAMIcode
                </Typography>
                <Typography sx={{ fontSize: 20, color: "#FFFFFF", fontWeight: "bold" }} >
                  {brand}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: 16, color: "#FFFFFF", fontWeight: "bold", display: "flex", justifyContent: "center", textAlign: "center", marginTop: 6 }} >
                XXXX XXXX XXXX {cardNumber}
              </Typography>
              <Typography sx={{ fontSize: 16, color: "#FFFFFF", fontWeight: "bold", display: "flex", marginLeft: 25, marginTop: 3 }} >
                {expMonth}/{expYear}
              </Typography>
            </Paper>
          </Box>
        </>
      )
    }
  }

  const InputFormView = () => {
    if (isLoading) {
      return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>

    } else {
      return (<>
        <h4 style={{ color: mainColor, display: "flex", textAlign: "center", justifyContent: "center", marginTop: "60px" }}> Enter Your Card Details </h4>
        <form onSubmit={handleCreateCardDetail}  >
          <div className="row">
            <div className="col-sm-11">
              <label for="name" >Card Number</label>
              <input
                type="tel"
                className="form-control"
                // value={number || ("")}
                name="number"
                maxlength="16"
                pattern="[0-9]+"
                onChange={(e) => { SetNumber(e.target.value); setNetworkRes('') }}
                onFocus={(e) => SetFocus(e.target.name)}
              ></input>
              {cardNumberError && <p style={errorText}> {cardNumberError} </p>}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-11">
              <label for="name"> Card Holder Name</label>
              <input
                type="text"
                className="form-control"
                // value={name || ("")}
                name="name"
                // pattern="[a-zA-Z]+"
                onChange={(e) => {
                  SetName(e.target.value);
                }}
                onFocus={(e) => SetFocus(e.target.name)}
              ></input>
              {cardNameError && <p style={errorText}> {cardNameError} </p>}
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col=sm-8"
              style={{
                ...{ "padding-right": "12em" },
                ...{ "padding-left": "1em" },

              }}
            >
              <label for="month">Expiration Year And Month</label>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <select
                className="form-control"
                name="expiry"
                // value={expiry || ("")}
                onChange={handleExpiry}
              >
                <option value=" ">Year</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
              </select>

            </div>
            &nbsp;
            {expiry && <div className="col-sm-4">
              <select
                className="form-control"
                name="expiry"
                // value={month || ("")}
                onChange={handleDate}

              >
                <option value=" ">Month</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>}
            {yearError && <p style={errorText}> {yearError} </p>}
            {monthError && <p style={errorText}> {monthError} </p>}
          </div>
          <br />
          <div className="col-sm-3">
            <div className="col=sm-4">
              <label for="cvv">CVV</label>
            </div>
            <input
              type="tel"
              name="cvc"
              maxlength="3"
              className=" form-control card"
              // value={cvc || ("")}
              pattern="\d*"
              onChange={(e) => {
                SetCvc(e.target.value);
              }}
              onFocus={(e) => SetFocus(e.target.name)}
            ></input>
            {cvcNumberError && <p style={errorText}> {cvcNumberError} </p>}
          </div>
          <br />
          {networkRes && <p style={errorTextNetwork}> {networkRes} </p>}
          <input
            type="submit"
            className="btn btn-secondary form-control"
            value="SAVE"
            disabled={disabled}
          />
          <br />
        </form>
      </>)
    }
  }
  const detetedMessage = () => {
    return (
      <Box sx={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ color: "green", fontSize: "20px", alignItems: "center", }}>Card Deleted Successfully!</Box>
      </Box>
    )
  }
  if (isLoading) {
    return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>
  } else {
    return (
      <>
        {(cardDisplay == true && save == false) && UserCard()}
        {(save == true) && detetedMessage()}
        {(formDisplay == true) && InputFormView()}
        {(cardDisplay == true && formDisplay == false) && <Box sx={{ color: mainColor, display: "flex", textAlign: "center", justifyContent: "space-evenly" }}>
          <CustomButton variant="contained" onClick={handleDeleteCard} sx={{ display: "center", textAlign: "center", justifyContent: "center", marginTop: 10, marginBottom: 10 }}>Delete CARD</CustomButton>
        </Box>}

        {((formDisplay == false && cardDisplay == false && save == false)) && <Box sx={{ color: mainColor, display: "center", justifyContent: "center", textAlign: "center" }}>
          <CustomButton variant="contained" onClick={handleAddCard} sx={{ display: "center", textAlign: "space-between", justifyContent: "center", marginTop: 10 }} >ADD YOUR CARD</CustomButton>
        </Box>}
        {(cardDisplay == true && formDisplay == false) && confirmDialog()}

      </>
    )
  }
}
