import React from 'react';
import { Routes, Route, Link } from "react-router-dom";



function Account() {

    return (
      <>

        <main style={{ height: "57vh" }}>
    
          <p>
            You're now inside <em>Account</em>
          </p>
        </main>

        
       
      </>
    );
  }

export default Account
