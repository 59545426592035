import { dividerClasses } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { validateforeverform } from "../../Business/AuthenticationBusiness";
import { Forevermapping } from '../../Repository/Api';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';





function CamiForever() {

  const initialValues = {
    camicode: "",
    email: ""
  };
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(true);
  const [error, setError] = useState("")
  const theme = useTheme();
  const handleChange = (e) => {
    setFormErrors("")
    setShowError("")
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCloseAlert = () => {
    setShowAlert(false)
  };

  const handleSubmit = (e) => {
    setShowError(false)
    e.preventDefault();
    const validation = validateforeverform(formValues);
    setFormErrors(validation.errorList);
    if (validation.status == true) {
      setIsLoading(true)
      const mapDetails = {
        "encodeId": formValues.camicode,
        "email": formValues.email
      }
      const res = Forevermapping(mapDetails).then((res) => {
        setIsLoading(false)
        if (res == "Already Mapped") {
          setShowError(true)
          setError("This CAMI-forever code is already activated by someone.")
          setFormValues(initialValues)
        }else if(res == "camicode is incorrect"){
          setShowError(true)
          setError("Please enter the  valid camicode")
          setFormValues(initialValues)
        }
         else {
          setShowAlert(true);
          setFormValues(initialValues)
        }
      }).catch((error) => {
        setShowError(true);
        setError("please try again")
        setIsLoading(false)
        setFormValues(initialValues)
      });
    }
  }

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(whiteColor),
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));

  const errorText = {
    color: "red",
    fontSize: 13,
    marginTop: 1,
    marginBottom: 1,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const mainColor = "#981D97";
  const whiteColor = "#000";
  const xlPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 80,
    width: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
    marginBottom: 60
  };
  const inputFieldColor = "#ecedee";
  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            marginBottom: 3,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Box>
    );
  } else if (showAlert) {
    return (
      <Box>
        <Box  sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "60vh", maxWidth: "100%", }}>
          <Alert onClose={handleCloseAlert} severity="success">
          Your CAMIforever code is activated successfully.
          </Alert>
        </Box>
      </Box>
    );
  } else {
    return (
      <>
        <Paper elevation={2} style={xlPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: '100%',
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#981D97",
                   
                  }}
                >
                Activate Your CAMI-forever code
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "auto",
                    mb: 2,
                  }}
                >
                  <InputBase
                    size="small"
                    id="camicode"
                    placeholder="CAMI-forever code"
                    name="camicode"
                    autoComplete="code"
                    autoFocus
                    value={formValues.code}
                    onChange={handleChange}
                    sx={{
                      mt: 2,
                      mb: 2,
                      backgroundColor: inputFieldColor,
                      paddingBlock: 1,
                      paddingInlineStart: 1,
                      paddingInlineEnd: 1,
                      borderRadius: 1,
                    }}
                  />
                  {formErrors.code && (<p style={errorText}>{formErrors.code}</p>)}
                  {formErrors.codeValid && (<p style={errorText}>{formErrors.codeValid}</p>)}
                </Box>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "auto",
                    mb: 2,
                  }}
                >
                  <InputBase
                    size="small"
                    id="email"
                    placeholder="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                    sx={{
                      mt: 2,
                      mb: 2,
                      backgroundColor: inputFieldColor,
                      paddingBlock: 1,
                      paddingInlineStart: 1,
                      paddingInlineEnd: 1,
                      borderRadius: 1,
                    }}
                  />
                  {formErrors.email && (<p style={errorText}>{formErrors.email}</p>)}
                  {formErrors.emailValid && (<p style={errorText}>{formErrors.emailValid}</p>)}
                  {showError && (<p style={errorText}>{error}</p>)}
                  <ColorButton
                    type="submit"
                    sx={{
                      borderRadius: 30,
                      width: 140,
                      alignItems: "center",
                      marginLeft: "80px",
                      marginTop: "40px"
                    }}
                    variant="contained"
                  >
                    Activate
                  </ColorButton>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Paper>
      </>
    );
  }
}

export default CamiForever;