import React, { useEffect, useState } from 'react';
import { getCurrentSignedInUser, getUserLoginStatus } from '../../Repository/Authentication/Firebase';

const ManageAuthModule = () => {

    let CSIU;
    let [loginState, setLoginState] = useState(false);
    /**
     * Constructor/ComponentWillMount
     */
    useEffect(() => {
        CSIU = getCurrentSignedInUser().subscribe((event) => {
            if (event.state === "logged in") {
                setLoginState(true);
            } else if (event.state === "logged out") {
                setLoginState(false);
            }
        });
        getUserLoginStatus();
        return () => {
            CSIU.unsubscribe();
        }
    }, []);


    return (
        <>Login Status</>
    );
};

export default ManageAuthModule;