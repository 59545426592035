import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import logoWhite from "../../assets/images/logo-white-horizontal.png";

import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import { useLocation, useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect } from "react";
import { useState } from "react";


function Item(props) {
  const { sx, ...other } = props;


  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "#fff",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};


export default function Footer() {
  const currentPath = useLocation();
  const [showUI, setShowUI] = useState(true);

  useEffect(() => {
    const path = currentPath.pathname;
    const excludePath = ['/login', '/register']
    if (excludePath.includes(path)) {
      setShowUI(false)
    }
    else setShowUI(true)
  }, [currentPath])


  return (

    <>
      <div
        style={{
          width: "100%",
          bottom: 0,
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "grid" },
            gridTemplateRows: "repeat(3)",
            backgroundColor: "#981D97",
            borderColor: "#981D97",
            width: "100%",


          }}
        >
          <Box
            sx={{
              backgroundColor: "#981D97",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              borderColor: "#981D97",
            }}
          >
            <Item
              sx={{
                backgroundColor: "#981D97",
                borderColor: "#981D97",
                paddingLeft: 10,
                //   height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logoWhite}
                alt="Logo"
                style={{ height: 60, paddingRight: 5, margin: 5 }}
              />
            </Item>
            <Item
              sx={{
                backgroundColor: "#981D97",
                borderColor: "#981D97",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginLeft: 10,
                whiteSpace: "nowrap",
                marginTop: 0.5
              }}
            >
              <Link href="" underline="none" color="#981D97"
                style={{
                  padding: 0,
                  backgroundColor: "#981D97",
                  borderColor: "#981D97",
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                Mobile App
              </Link>
              <Link href="https://camicode.com/pages/privacy-policy" underline="none" color="#981D97"
                style={{
                  padding: 0,
                  backgroundColor: "#981D97",
                  borderColor: "#981D97",
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                Privacy Policy
              </Link>
              <Link href="https://camicode.com/pages/website-terms-and-conditions" underline="none" color="#981D97"
                style={{
                  padding: 0,
                  backgroundColor: "#981D97",
                  borderColor: "#981D97",
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                Terms and Conditions
              </Link>
            </Item>
            <Item
              sx={{
                backgroundColor: "#981D97",
                borderColor: "#981D97",
                whiteSpace: "nowrap",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                marginTop: 0.5
              }}
            >
              <Link href="https://camicode.com/pages/contact" underline="none" color="#981D97"
                style={{
                  padding: 0,
                  backgroundColor: "#981D97",
                  borderColor: "#981D97",
                  color: "#fff",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                }}
              >
                Support
              </Link>
              <Link href="https://camicode.com/pages/about-cami" underline="none" color="#981D97"
                style={{
                  padding: 0,
                  backgroundColor: "#981D97",
                  borderColor: "#981D97",
                  color: "#fff",
                  fontWeight: "500",
                  lineHeight: 0,
                  whiteSpace: "nowrap",
                  paddingTop: 10

                }}
              >
                About CAMI
              </Link>
            </Item>
          </Box>

          <Box
            sx={{
              marginHorizontal: 5,
              backgroundColor: "#981D97",
              borderColor: "#981D97",
              color: "#fff",
              textAlign: "center",
              fontWeight: "500",
              width: "100%",
              fontSize: 14,
              padding: 2
            }}
          >
            &copy; {(new Date().getFullYear())} CAMI TECHNOLOGIES. All Rights Reserved. CAMI and its logo
            are registered trademarks of CAMI TECHNOLOGIES.
          </Box>
        </Box>
      </div>

      {/* <-----Mobile Footer----> */}
    {showUI == true && 
     <Box
        sx={{
          pb: 7,
          display: { xs: "0", sm: "600", md: "none", lg: "none", xl: "none" },
        }}
      >
        <CssBaseline />
        {/* <List>
            <p>Test Field</p>
          </List> */}
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation showLabels sx={{ backgroundColor: "#981D97" }}>
            <BottomNavigationAction
              href="/created-camicode"
              // sx={{ color: "#fff" }}
              sx={{ fontSize: 13, color: "#fff", minWidth: "max-content" }}
              label="Created CAMIcodes"

            />

            <BottomNavigationAction
              href="/cami-steps"
              icon={
                <CameraAltIcon
                  sx={{
                    width: 55,
                    height: 55,
                    borderRadius: 23,
                    marginTop: -8,
                    backgroundColor: "#80CBC4",
                    color: "white",
                    padding: 1,
                  }}
                />
              }
            />

            <BottomNavigationAction
              href="/scan-cami"
              sx={{ color: "#fff", minWidth: "max-content" }}
              label="Scan CAMIcodes"
            />
          </BottomNavigation>
        </Paper>
      </Box> }
    </> 

  );
}
