import { async } from "@firebase/util";

export let baseURL = "https://api.camicode.com";
// export let baseURL = "https://api-camicode.azurewebsites.net/";
//  export let baseURL = "https://camicode-api-qa.azurewebsites.net";
//  export let baseURL = "https://staging-api.camicode.com/";
// export let baseURL = "https://staging-api.camicode.com";

export let UpdateUserInfo = (data) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        return fetch(baseURL + "/api/User/UpdateUser", {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Authorization': token
            },
            body: data,
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const createUser = (data) => {
    return new Promise(async (resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            const response = await fetch(baseURL + "/api/User/CreateUser", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            }).then((res) => {
                resolve(res.json());
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}

export const sendToUser = (dataToSend) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            fetch(baseURL + "/api/CamiCode/CreateSharing", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify(dataToSend),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((res) => {
                resolve(res.json());
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}

export const SendToForeverUser = (dataToSend) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            fetch(baseURL + "/api/CamiCode/ReplaceForever", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify(dataToSend),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((res) => {
                resolve(res.json());
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}

export const  getToken=(datatosend)=>{
    return new Promise ((resolve,reject)=>{
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            fetch(baseURL +'/api/Notification/CreateNotification', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify(datatosend),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((res) => {
                resolve(res.json());
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    })
}

 export const sendPushNotification=(datatoken)=>{
    let obj = {
        "registration_ids":datatoken,
        "notification":{
          "title":"camicode","body":"you have received a camicode"
        }
      }
    return new Promise((resolve,reject)  =>{
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try{
            fetch("https://fcm.googleapis.com/fcm/send",{
                method:"POST",
                mode:"cors",
                cache:"no-cache",
                body:JSON.stringify(obj),
                headers:{
                    "content-Type":'application/json',
                    'Authorization':"key=AAAAGXEZLSM:APA91bGak2HdguqVR4DLYjea3VuAYNtiARF57Fx9k3wUaPnMmLBp5T6IqdBH90t6fvz-CYN7ws69B5N4rwJ3fHZ9yaSyuksHf9yrnIaQQ78rbhG4yMefJuwjgQg3mQhDOZaewUj68HEF"
                },
            }).then((res)=>{
            resolve(res.json)
            }).catch((error)=>{
                reject(error);
            })
        }catch (error) {
             reject(error);
        }
 });
}

export let checkVideoAvailability = (camicodeId, password) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        fetch(baseURL + '/api/CamiCode/GetCamiCode?id=' + camicodeId + '&uniqueId=' + password, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then((response) => response.json())
            .then(result => {
                resolve(result);
            })
            .catch((error) => {
                reject({
                    status: 'error',
                    camicodeId: '',
                    isVideoAvailable: false,
                    message: error
                });
            });
    });
}

export let getUserByAuthId = (authId) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        fetch(baseURL + '/api/User/GetUserByAuthId?authId=' + authId, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                resolve(result);
            })
            .catch((error) => {
                reject({
                    status: 'error',
                    authId: authId,
                    message: error
                });
            })
    });


}

export let getUserByEmail = (data) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        return fetch(baseURL + "/api/User/GetUserByEmail?email=" + data, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then((response) => {
                // console.log(response, "resp from API");
                if (response.status === 200) {
                    resolve(response.json());

                }
                else {
                    reject(response)
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

}

export let getCamiCode = (data, password) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            fetch(baseURL + "/api/CamiCode/GetCamiCode?id=" + data + '&uniqueId=' + password, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((res) => {
                resolve(res.json());
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}

export const listCamiCode = async (pageNumber, pageSize, filterId, searchInput) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    try {
        const _baseUrl = baseURL + '/api/CamiCode/GetCamiCodesListWithPagination';
        const params = new URLSearchParams({
            PageNumber: pageNumber,
            PageSize: pageSize,
            FilterId: filterId,
            SearchTerm: searchInput,
            SortBy: 'desc'
        });
        const res = await fetch(_baseUrl + '?' + params.toString(), {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        return await res.json();
    } catch (error) {
        console.error(error);
    }
}


export const listReceivedCamiCode = async (pageNumber, pageSize, filterId, searchInput) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    try {
        const _baseUrl = baseURL + '/api/CamiCode/GetReceiversListWithPagination';
        const params = new URLSearchParams({
            PageNumber: pageNumber,
            PageSize: pageSize,
            FilterId: filterId,
            SearchTerm: searchInput,
            SortBy: 'desc'
        });
        const res = await fetch(_baseUrl + '?' + params.toString(), {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        return await res.json();

    } catch (error) {
        console.error(error);
    }
}

export let mapCamitagAndVideoApi = (data) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            const url = baseURL + "/api/CamiCode/CreateCamiCode";
            fetch(url, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
}
export let UpdateCAMIApi = (data) => {
    console.log(data, ' - UpdateCAMIApi')
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            const url = baseURL + "/api/CamiCode/UpdateCamiCode";
            fetch(url, {
                method: "PUT",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
}

export let getCamitagFromId = (CamiPrimaryId) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            const url = baseURL + "/api/CamiCode/GetCamiCodeId?id=" + CamiPrimaryId;
            fetch(url, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
}
export let verifyEmailFirebase = (id) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        fetch(baseURL + '/api/User/verifyEmailFirebase?uid=' + id, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then((response) => response.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            })
    });


}
export const GetFreeCamiCodesListWithPagination = async (pageNumber, pageSize, filterId, searchInput) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    try {
        const _baseUrl = baseURL + '/api/CamiCode/GetFreeCamiCodesListWithPagination';
        const params = new URLSearchParams({
            PageNumber: pageNumber,
            PageSize: pageSize,
            FilterId: filterId,
            SearchTerm: searchInput,
            SortBy: 'desc'
        });
        const res = await fetch(_baseUrl + '?' + params.toString(), {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        return await res.json();

    } catch (error) {
        console.error(error);
    }
}

export const GetCamiForeverCodesListWithPagination = async (pageNumber, pageSize, filterId, searchInput,email) => {
    console.log(filterId)
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    try {
        const _baseUrl = baseURL + '/api/CamiCode/CamiForeverPagination';
        const params = new URLSearchParams({
            PageNumber: pageNumber,
            PageSize: pageSize,
            // FilterId: filterId,
            SearchTerm: searchInput,
            email:email,
            SortBy: 'desc'
        });
        const res = await fetch(_baseUrl + '?' + params.toString(), {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        return await res.json();

    } catch (error) {
        console.error(error);
    }
}


export const createPaymentMethod = async (data) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        fetch(baseURL + "/api/Stripe/create-payment-method", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log(response)
                resolve(response.json())
                console.log("inside create payment method api");
            })
            .catch((error) => {
                reject(error);
            })
    });
}
export const getCardDetails = async (data) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        fetch(baseURL + '/api/Stripe/get-card-details'
            , {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            })
            .then((response) => {
                console.log(response);
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            })
    });

}
export const updateCardDetails = async (data) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        fetch(baseURL + '/api/Stripe/update-payment-method'
            , {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            })
            .then((result) => {
                resolve(result.json());
                console.log(result, "inside api");
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export const deleteCard = async (pmId) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        fetch(baseURL + '/api/Stripe/detach-card-details?pmId=' + pmId
            , {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            })
            .then((response) => response)
            .then((result) => {
                resolve(result);
                console.log("inside api");
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export const createTransaction = async (data) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        fetch(baseURL + "/api/Reports/CreateTransaction", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log(response)
                resolve(response.json())
                console.log("inside create transation method api");
            })
            .catch((error) => {
                reject(error);
            })
    });
}


export let getTransactions = (userId) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        fetch(baseURL + '/api/Reports/GetTransactions?userId=' + userId, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then((response) => {
                console.log(response)
                resolve(response.json())
                console.log("inside get transation method api");
            })
            .catch((error) => {
                reject(error);
            })
    });
}



export let UpdateViewCount = (camiId, loggedUser) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        return fetch(baseURL + "/api/CamiCode/UpdateViewCount?id=" + camiId + "&loggedUser=" + loggedUser, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Authorization': token
            },
            body: JSON.stringify(camiId, loggedUser)
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export const getTransactionsWithPagination = async (pageNumber, pageSize, filterId, searchInput) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    try {
        const _baseUrl = baseURL + '/api/Reports/GetTransactionsWithPagination';
        const params = new URLSearchParams({
            PageNumber: pageNumber,
            PageSize: pageSize,
            FilterId: filterId,
            SearchTerm: searchInput,
            SortBy: 'desc'
        });
        const res = await fetch(_baseUrl + '?' + params.toString(), {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        return await res.json();
    } catch (error) {
        console.error(error);
    }
}

export const uploadPdfImage = async (file) => {
    const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
    return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append("File", file,file['name']);
        fetch(baseURL + "/api/Storage/UploadFile", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Authorization': token,
            },
            body: formData
        })
        .then(response => response.text())
            .then((response) => {
                console.log(response)
                resolve(response)
                console.log("upload file");
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export const viewCountDetails = (data) => {
    return new Promise(async (resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            const response = await fetch(baseURL + "/api/CamiCode/CreateViewCountDetails", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            }).then((res) => {
                resolve(res.json());
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}


export let Forevermapping=(data)=>{
    return new Promise((resolve,reject)=>{
     const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
     try{
     return fetch(baseURL + '/api/CamiCode/MappingForeverType',{
        method:"POST",
        mode:"cors",
        cache:"no-cache",
        headers:{
            "content-type":"application/json",
            "AUthorization":token
        },
        body:JSON.stringify(data)
     }).then((response) =>{
        if(response.status == 200){
           resolve(response.text())
        }
        else if(response.status == 204){
            resolve(response)
        }
        else{
            response.json() .then((result) => {
                resolve(response);
            }).catch((error)=>{
               reject(error)
            })
        }
     }
    )
    }catch(error){
       reject(error)
    }
});
}


export let findlocationmapping = (data) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        try {
            const url = baseURL + "/api/User/FindLocation";
            fetch(url, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
}

export let Updatectacount = (camiId) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        return fetch(baseURL + "/api/CamiCode/UpdateCTACount?id=" + camiId, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Authorization': token
            },
            body: JSON.stringify(camiId)
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export let UpdatectacountLocation = (camiId) => {
    return new Promise((resolve, reject) => {
        const token = 'Bearer ' + localStorage.getItem('JWTTOKEN');
        return fetch(baseURL + "/api/CamiCode/UpdateCTACountInLocation?id=" + camiId, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Authorization': token
            },
            body: JSON.stringify(camiId)
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            });
    });
}
 

const ModulesExported = {
    createUser,
    checkVideoAvailability,
    getUserByEmail,
    listCamiCode,
    mapCamitagAndVideoApi,
    getCamitagFromId,
    verifyEmailFirebase,
    GetFreeCamiCodesListWithPagination,
    createPaymentMethod,
    getCardDetails,
    updateCardDetails,
    deleteCard,
    createTransaction,
    getTransactions,
    UpdateViewCount,
    getTransactionsWithPagination,
    uploadPdfImage,
    viewCountDetails,
    getToken,
    sendPushNotification,
    GetCamiForeverCodesListWithPagination,
    findlocationmapping,
    Updatectacount,
    UpdatectacountLocation,

};
export default ModulesExported;