import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import { getUserByAuthId, createTransaction, getTransactions, getTransactionsWithPagination, getUserByEmail } from '../../Repository/Api';
import { useState, useEffect } from "react";
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Fade from '@mui/material/Fade';
import useDebounce from '../Components/UseDebounceHook';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';

export default function MyTransaction() {

    const [userId, setUserId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [transactionData, setTransactionData] = useState([]);
    const [historyPresent, setHistoryPresent] = useState(true)

    // pagination
    const [searchInput, setSearchInput] = useState('');
    const [pageNumber, setPageNumber] = useState(1)
    const [page, setPage] = useState(1)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [filterId, setFilterId] = useState("");
    const debouncedSearchTerm = useDebounce(searchInput, 500);
    const[data,setData] =useState([]);
    const theme = useTheme();
    const smallDevices = useMediaQuery(theme.breakpoints.down("md"));



    const CustomIconButton = styled(IconButton)(({ theme }) => ({
        color: theme.palette.getContrastText(whiteColor),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));

    const Img = styled("img")({
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
    });

    const descriptionStyle = {
        fontSize: 10,
        textDecoration: "none",
        textOverflow: 'ellipsis',
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: "bold",
        minHeight: "20px"
    }
    const titleStyle = {
        fontSize: 10,
        fontWeight: "bold",
        textDecoration: "none",
        minHeight: "20px"
    }

    const imgStyle = {
        width: 200,
        resize: "cover",
        marginBottom: 15,
        paddingTop: 5,
    }

    const mainColor = "#981D97";
    const whiteColor = "#000";



    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "flex-start",
        marginBottom: 3,
        margin: 1,
        textDecoration: "none",
        width: { sm: '100%', md: 'inherit' },
        padding: '16px',
        elevation: 2,
        backgroundColor: "#fff",
        textDecoration: "none",
        height: { sm: '100%', md: 'inherit' },
    }

    const imgBoxStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"

    };

    const textBoxStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textDecoration: "none",
        paddingLeft: 2,
        height: "100%"
    };




    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    console.log(user);
                    getUserFromUid(user.uid);
                    console.log(user.uid);
                });
            } else {
                return ((error => console.error))
            }
        }
        );


        const getUserFromUid = async (authId) => {
            const response = await getUserByAuthId(authId)
                .then(response => {
                    console.log(response.id, 'res');
                    setUserId(response.id)
                    console.log("inside");
                }).catch(error => console.log(error, "error receiving data by authId"))
        }
    }, [])

    useEffect(() => {
        if (userId) {
            getTransactionsDetail();
        };
    }, [userId])

    const getTransactionsDetail = (e) => {
        console.log(userId, 'userid');
        getTransactions(userId)
            .then((response) => {
                // console.log(response);
                if (response.length > 0) {
                    setIsLoading(false)
                    setTransactionData(response)
                } else {
                    setHistoryPresent(false)
                    setIsLoading(false)
                }
            }).catch((error) => {
                console.log((error));
            })
    }

    useEffect(() => {
        console.log(data, 'details');
    }, [data])


    const fetchData = async (user) => {


        try {
            const getUserDetails = await getUserByEmail(user)

            setFilterId(getUserDetails?.id);
            console.log(filterId, "Print user ID of current User")
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {

        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    fetchData(user.providerData[0].email);
                });
            } else {
                return ((error => console.error))
            }

        }
        );
    }, []);
    useEffect(requestData, [pageNumber, pageSize, filterId, debouncedSearchTerm]);


    function requestData() {
        const auth = getFirebaseAuthModule();
        console.log(auth.currentUser, "auth from received");
        if (!auth.currentUser) {
            return ((error => console.log(error)))

        }

        (async () => {
            setIsLoading(true);
            if (filterId) {
                const data = await getTransactionsWithPagination(pageNumber, pageSize, filterId, searchInput)
                setData(data.list)
                console.log(data.list, 'list');
                setNumberOfPages(data?.totalPages)
                setIsLoading(false);
                setSearchInput(data?.searchTerm);
                setPage(data?.pageNumber);
                console.log(data?.pageNumber, data?.totalPages, data, "data from APIIIIII")
            } else {
                setTimeout(() => {
                    return ("Failed to Fetch Data")
                }, 2500);
            }
        })()
    }


    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
    }


    function handlePaginationChange(event, pageNumber) {
        setPageNumber(pageNumber);
        console.log('Requested Page Number: ', pageNumber);
    }



    if (isLoading) {
        return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px' }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>
    } else if (historyPresent == false) {
        return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px', color: mainColor, fontSize: "20px" }} >You are yet to start your Transactions!</Box>
    } else {
        return (
            <Box sx={{ marginBottom: 5 }}>
                {data.map((item) => <Paper
                    sx={{
                        p: 2,
                        margin: 'auto',
                        maxWidth: 450,
                        marginTop: 5,
                        flexGrow: 1,
                        display: "flex",
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }}
                >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="subtitle1" gutterBottom sx={{ color: mainColor }}>
                                        Transaction ID&nbsp;: {item.transactionId}
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom sx={{ color: mainColor }}>
                                        Amount<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>: $ {(item.transactionAmount / 100)}
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom sx={{ color: mainColor }} >
                                        Status<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>: {item.transactionStatus}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", position: "relative", bottom: 5, padding: 4 }}>
                    <Pagination count={numberOfPages} page={page} onChange={handlePaginationChange} color="primary" />
                </Box>
            </Box>
        );
    }
}


