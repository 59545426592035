import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';

import google from "./../../assets/images/socialMedia/google.svg";
import apple from "./../../assets/images/socialMedia/apple.svg";
import facebook from "./../../assets/images/socialMedia/facebook.svg";
import { 
  createFirebaseAccountWithFacebook, 
  createFirebaseAccountWithGoogle, 
  createFirebaseAccountWithPassword, 
  requestPasswordReset, 
  validateForgetPasswordForm, 
  validateSignupForm } from '../../Business/AuthenticationBusiness';
import { useNavigate } from 'react-router-dom';


const ForgetPassword = () => {
 
    const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const xlPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 80,
    width: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
  };
  const smPage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 2,
  };
  const errorText = {
    color: "red",
    fontSize: 13,
    marginTop: 1,
  };
  const socialLogo = {
    padding: 16,
    paddingHorizontal: 20,
    height: 64,
    cursor: 'pointer'
  };
  const socialLogoContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width:"100%",
    my: 2,
  };
  const mainColor = "#981D97";
  const whiteColor = "#000";
  const inputFieldColor = "#ecedee";
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(whiteColor),
    backgroundColor: mainColor,
    "&:hover": {
      backgroundColor: "#bf3bbe",
    },
  }));
  const initialValues = {
    email: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = validateForgetPasswordForm(formValues);
    console.log(formValues, "print form value")
    setFormErrors(validation.errorList);
    console.log(formErrors, "from errors");
    if (validation.status===true) {
      setIsSubmit(true);
      requestPasswordReset(formValues)
        .then((success) => {  
          console.log(success, "success from password reset request");
        setIsSubmit(false);
        setApiError("We have sent a password reset link to your Email.");
        setFormValues(initialValues);
        setTimeout(() => {
            navigate('/login');
        }, 5000);
      })
        .catch((error) => {
        console.log('im here')
        setIsSubmit(false);
          if (error.message) {
          setApiError("User not registered with us.");
          }
      });
    }
  };

  /**HTML Section */
  const renderForgotPasswordForm = () => {
    if (matches) {
      return (
        <Box elevation={0} style={smPage}>
          <ThemeProvider theme={theme}>
          <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: '100%',
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box  >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "left",
                    alignItems: "flex-start",
                    color: "#981D97",
                  }}
                >
                  Forgot Password
                </Typography>
                { renderFPFormContent() }
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      );
    } else {
      return (
        <Paper elevation={2} style={xlPage}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                width: '100%',
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CssBaseline />
              <Box sx={{width: '100%'}} >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "flex",
                    alignItems: "flex-start",
                    color: "#981D97",
                  }}
                >
                  Forgot Password
                </Typography>
                { renderFPFormContent() }
              </Box>
            </Container>
          </ThemeProvider>
        </Paper>
      );
    }
  };

  const renderFPFormContent = () => {
    if (isSubmit===true) {
      return (
        <Box sx={{textAlign: "center"}} >
          <CircularProgress sx={{ 
            marginTop: 3, 
            marginBottom: 3, 
            marginLeft: "auto", 
            marginRight: "auto" 
          }} />
        </Box>
      );
    } else {
      return (<>{ loginFPContent()}</>);
    }
  }

  const loginFPContent = () => {
    return (
      <>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          
          <InputBase
            size="small"
            id="email"
            placeholder="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formValues.email}
            onChange={handleChange}
            sx={{
              width: '100%',
              mt: 2,
              backgroundColor: inputFieldColor,
              paddingBlock: 1,
              paddingInlineStart: 1,
              paddingInlineEnd: 1,
              borderRadius: 1,
            }}
          />
          {formErrors.email && (<p style={errorText}>{formErrors.email}</p>)}
          {formErrors.emailValid && (<p style={errorText}>{formErrors.emailValid}</p>)}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 3,
              marginBottom: 2,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ColorButton
              type="submit"
              sx={{
                borderRadius: 30,
                width: 230,
                margin: 3,
              }}
              variant="contained"
            >
              Reset Password
            </ColorButton>
          </div>
          {apiError && (<p style={errorText}>{apiError}</p>)}
        </Box>
      </>
    );
  };

  return <>{renderForgotPasswordForm()}</>;
}

export default ForgetPassword;
