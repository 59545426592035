
import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import camiCode from "../../assets/images/camiCode/CAMIcode.png"
import Pagination from '@mui/material/Pagination';

import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import CancelIcon from '@mui/icons-material/Cancel';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled, alpha } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { AddBoxTwoTone } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import usePagination from "../Components/Pagination"
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';
import { baseURL, getUserByEmail, listReceivedCamiCode } from "../../Repository/Api";
import { ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AppPagination from '../Components/Pagination/AppPagination';
import { Link } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import useDebounce from '../Components/UseDebounceHook';


function ReceivedCamiCode() {
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    //Pagination data
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1)
    const [page, setPage] = useState(1)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [filterId, setFilterId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const camiTagImage = baseURL + '/api/CamiCode/GenerateQrCode?camiCode=';
    const debouncedSearchTerm = useDebounce(searchInput, 500);
    const theme = useTheme();
    const smallDevices = useMediaQuery(theme.breakpoints.down("md"));


    const CustomIconButton = styled(IconButton)(({ theme }) => ({
        color: theme.palette.getContrastText(whiteColor),
        backgroundColor: mainColor,
        "&:hover": {
            backgroundColor: "#bf3bbe",
        },
    }));

    const Img = styled("img")({
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
    });

    const descriptionStyle = {
        fontSize: 10,
        textDecoration: "none",
        textOverflow: 'ellipsis',
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: "bold",
        minHeight: "20px"
    }
    const titleStyle = {
        fontSize: 10,
        fontWeight: "bold",
        textDecoration: "none",
        minHeight: "20px"
    }

    const imgStyle = {
        width: 200,
        resize: "cover",
        marginBottom: 15,
        paddingTop: 5,
    }

    const mainColor = "#981D97";
    const whiteColor = "#000";



    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "flex-start",
        marginBottom: 3,
        margin: 1,
        textDecoration: "none",
        width: { sm: '100%', md: 'inherit' },
        padding: '16px',
        elevation: 2,
        backgroundColor: "#fff",
        textDecoration: "none",
        height: { sm: '100%', md: 'inherit' },
    }

    const imgBoxStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"

    };

    const textBoxStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textDecoration: "none",
        paddingLeft: 2,
        height: "100%"
    };



    const fetchData = async (user) => {


        try {
            const getUserDetails = await getUserByEmail(user)

            setFilterId(getUserDetails?.id);
            console.log(filterId, "Print user ID of current User")
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {

        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                    fetchData(user.providerData[0].email || user.email);
                });
            } else {
                return ((error => console.error))
            }

        }
        );
    }, []);
    useEffect(requestData, [pageNumber, pageSize, filterId, debouncedSearchTerm]);


    function requestData() {
        const auth = getFirebaseAuthModule();
        console.log(auth.currentUser, "auth from received");
        if (!auth.currentUser) {
            return ((error => console.log(error)))

        }

        (async () => {
            setIsLoading(true);
            if (filterId) {
                const data = await listReceivedCamiCode(pageNumber, pageSize, filterId, searchInput)
                setData(data.list)
                setNumberOfPages(data?.totalPages)
                setIsLoading(false);
                setSearchInput(data?.searchTerm);
                setPage(data?.pageNumber);
            } else {
                setTimeout(() => {
                    return ("Failed to Fetch Data")
                }, 2500);
            }
        })()
    }


    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
    }
    function handlePaginationChange(event, pageNumber) {
        setPageNumber(pageNumber);
        console.log('Requested Page Number: ', pageNumber);
    }

    if (isLoading) {
        return <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '150px', minHeight: "calc(100vh - 250px)" }} ><CircularProgress sx={{ position: 'relative', width: '100%', margin: '0 auto' }} /></Box>
    } else {
        return (
            <Box sx={{ minHeight: "calc(100vh - 250px)" }}>
                {smallDevices && <Box sx={{ textAlign: "center" }}>
                    <Typography variant="h5" sx={{ color: mainColor, marginTop: 4 }}>Received CAMIcodes</Typography>
                    <TextField type='search' color="secondary" size='small'
                        sx={{ padding: 1, backgroundColor: "white", color: "black", marginTop: 4 }}
                        placeholder='Search CAMIcode'
                        value={searchInput}
                        onChange={(e) => searchItems(e.target.value)}
                    />
                    <Typography sx={{ color: mainColor, fontSize: 20, marginTop: 4, marginLeft: 2, fontWeight: "bold" }} gutterBottom component="div">
                        Download to view your videos forever!
                    </Typography>
                </Box>}
                <Box>
                    {!smallDevices && <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" sx={{ color: mainColor, marginTop: 2, marginLeft: 4 }}>Received CAMIcodes</Typography>
                        <Typography sx={{ color: mainColor, fontSize: 20, marginTop: 2, marginLeft: 2, fontWeight: "bold" }} gutterBottom component="div">
                            Download to view your videos forever!
                        </Typography>
                        <TextField type='search' color="secondary" size='small'
                            sx={{ padding: 1, backgroundColor: "white", color: "black", marginTop: 1, marginRight: 4 }}
                            placeholder='Search CAMIcode'
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value)}
                        />

                    </Box>}
                </Box>


                <>
                    <Box sx={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: "wrap", width: "100%", padding: 1
                    }}>
                        {data.map((item, key) => {
                            const _camiCode = item['camiCode'] || 'CAMI';
                            return (
                                <Link key={key} underline="none" to={'/camicard-received?c=' + item?.camiCode + '&p=' + item?.uniqueId} style={{ textDecoration: "none", color: mainColor, margin: '10px' }} >
                                    <Paper elevation={2} sx={containerStyle}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={("Your video will remain live for " + item.remainingDays + " more days, download to keep forver")}>
                                            <Box sx={{ textAlign: "right", width: "100%" }}>
                                                <Typography sx={{ textAlign: "right", width: "100%" }}> {item.remainingDays}/60 days</Typography>
                                            </Box>
                                        </Tooltip>
                                        <Box sx={imgBoxStyle}>
                                            <img src={camiTagImage + _camiCode} style={imgStyle}></img></Box>
                                        <Box sx={textBoxStyle}>
                                            <Typography variant="h6" sx={titleStyle}>{(item?.fromName) ? (item?.fromName) : ('')}</Typography>
                                            <Typography sx={descriptionStyle}>{(item?.subject) ? (item?.subject) : ("")}</Typography>
                                        </Box>
                                    </Paper>
                                </Link>
                            )
                        })}
                    </Box>
                </>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", position: "relative", bottom: 5, padding: 4 }}>
                    <Pagination count={numberOfPages} page={page} onChange={handlePaginationChange} color="primary" />
                </Box>
            </Box>
        );
    }

}

export default ReceivedCamiCode;
