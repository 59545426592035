import React from 'react'
import { Routes, Route, Link } from "react-router-dom";

import AppHeader from "../Components/AppHeader.js";

function YourCamicodes() {
  return (
    <>

      <AppHeader />
      <main style={{ height: "57vh" }}>
       
        <p>
          You're now inside <em>Your Camicodes</em>
        </p>
      </main>
    
    </>
  );
}

export default YourCamicodes
