import AppHeader from '../Components/AppHeader';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getFirebaseAuthModule } from '../../Repository/Authentication/Firebase';


export default function MediaLayout() {
    useEffect(() => {
        const auth = getFirebaseAuthModule();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((newToken) => {
                    localStorage.setItem("JWTTOKEN", newToken);
                   
                });
            } else {
                return ((error => {console.log(error)}))
            }
        }
        );
      }, []);
    return (
        <section>
            <AppHeader type='floating' />
            <Outlet />
        </section>
    )
}