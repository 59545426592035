import AppHeader from "../Components/AppHeader";
import Footer from "../Components/Footer";
import React, { useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
import { getCurrentSignedInUser, getFirebaseAuthModule } from "../../Repository/Authentication/Firebase";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

function AppLayout() {
  let [isLoading, setIsLoading] = useState(true);
  let [lstate, setLState] = useState(false);
  var navigate = useNavigate();

  useEffect(() => {
    const auth = getFirebaseAuthModule();
    auth.onAuthStateChanged((user) => {
        if (user) {
            user.getIdToken().then((newToken) => {
                localStorage.setItem("JWTTOKEN", newToken);
               
            });
        } else {
            return ((error => {console.log(error)}))
        }
    }
    );

    getCurrentSignedInUser().subscribe((res) => {
      if (res['state'] ) {
        setIsLoading(false);
        setLState(true);
      } else {
        setLState(false);
        navigate('/login');
      }
    });
  }, []);

  if (!isLoading) {
    return (
      <section>
        <AppHeader />
        <div style={{ minHeight: 'calc(100vh - 225px)' }} >
          <Outlet />
        </div>
        <Footer />
      </section>
    )
  } else {
    return <Box sx={{ display: 'flex', marginTop: 25, marginBottom: 25, width: '100%' }}><CircularProgress sx={{ margin: '0 auto' }} /></Box >
  }

}
export default AppLayout;
