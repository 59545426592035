import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader";


function MyCamicode() {
  return (
    <>
     
      <main style={{ height: "50vh" }}>
       
        <p>
          You're now inside <em>My Camicode</em>
        </p>
      </main>
    
    </>
  );
}

export default MyCamicode
